import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe'

import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import InputBase from '@material-ui/core/InputBase';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import QRCode from 'qrcode';
import * as Stripe from 'stripe'

import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';
import Utl from './Utl';


import AgoraRTC from 'agora-rtc-sdk-ng'

// const { RtcTokenBuilder, RtcRole } = require('agora-access-token')
// import { RtcTokenBuilder, RtcRole } from 'agora-access-token';

var init = true;

const url = Utl.json2url({"iframe":[{"type":"webRTC","role":"host"}]},"https://"+Utl.CONF.paypal.web);
var qrc;
QRCode.toDataURL(url, function (err, url) {
  qrc = url;
});


const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(8),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '80%',
    },
  },
  button: {
    margin: theme.spacing(1),
  },

  player: {
    height: "100%",
    width: "100%",
    "z-index":"2000",
    "pointer-events": "none",
    position:"fixed"
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  video: {
    // display: "flex",
    // width: "100%",
    // "justify-content": "center",
    // "align-items": "center",
    "background-color": "#000",
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    // textAlign: "center",
    width: Utl.CONF.defaultWidth/2+"px",
    height: Utl.CONF.defaultHeight/2+"px",
  },
  qr:{
    display: "block",
    width: "100%",
    "max-width":"200px",
    height: "auto",
    margin : "0 auto"
  },
  video2: {
    "background-color": "#000",
    display: "block",
    // "margin-left": "auto",
    // "margin-right": "auto",
    // textAlign: "center",
    width: "100%",
    height: "auto",
  },
  frame: {
    position: 'absolute',
    frameborder:'no',
    width:'100%',
    height:'100%',
  },
  paypal: {
    display: "none",
  },
});



const Custompage = props => {
  const { classes, user , match , CONF,Dp} = props;
  var { json } = match.params;
  var header = (<Header user={user} login="/Login/target/mypage" Dp={{}}/>);

  if(!json) {
    json = "JTI1N0IlMjUyMmlmcmFtZSUyNTIyJTI1M0ElMjU1QiUyNTdCJTI1MjJ0eXBlJTI1MjIlMjUzQSUyNTIyd2ViUlRDJTI1MjIlMjUyQyUyNTIycm9sZSUyNTIyJTI1M0ElMjUyMmF1ZGllbmNlJTI1MjIlMjU3RCUyNTVEJTI1N0Q=";//match.params;
    header = "";
  }

  const [state, setState] = React.useState(0);

  console.log(json)

  async function agora(role,channelName){

    const client = AgoraRTC.createClient({mode: "live", codec: "vp8"});

    // // Fill the appID and appCertificate key given by Agora.io
    const appID = Utl.CONF.agora.id;//""//process.env.APP_ID;
    //
    // // token expire time, hardcode to 3600 seconds = 1 hour
    // const expirationTimeInSeconds = 3600
    // // const role = RtcRole.PUBLISHER
    //
    // // const generateRtcToken = function(req, resp) {
    //   const currentTimestamp = Math.floor(Date.now() / 1000)
    //   const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
    //   // const channelName = "Custompage"//req.query.channelName;
    //   // use 0 if uid is not specified
      var uid = 0//req.query.uid || 0
    //   // if (!channelName) {
    //   //   return resp.status(400).json({ 'error': 'channel name is required' }).send();
    //   // }
    //
    //   var token = await RtcTokenBuilder.buildTokenWithUid(appID, appCertificate, channelName, uid, role, privilegeExpiredTs);
    //
    // //   return resp.json({ 'key': key });
    // // }

    // Utl.CONF.firebase.functions = "http://localhost:5001/dp-xr-staging/us-central1";

    var usage = Utl.CONF.firebase.functions+"/buildTokenWithUid?uid="+channelName+"&role="+role;
    var res = await Axios.get(usage)
    var token = res.data;

    var timerObj
    var timer = ()=>{
      if(timerObj)clearInterval(timerObj);
      setInterval(()=>{
        Axios.get(usage);
      }, Utl.CONF.agora.usage);
    }

    if(token == "checkout"){
      setState("checkout");

    }else if(role=="host"){
      const localTracks = {
        videoTrack: null,
        // audioTrack: null
      };

      client.setClientRole(role);

      [uid, localTracks.videoTrack] = await Promise.all([
        // join the channel
        client.join(appID, channelName, token),
        // create local tracks, using microphone and camera
        AgoraRTC.createCameraVideoTrack({
          "facingMode":"environment",
          encoderConfig: {
            width: Utl.CONF.defaultWidth,
            // Specify a value range and an ideal value
            height: Utl.CONF.defaultHeight,
            frameRate: 30,
            bitrateMin: 600, bitrateMax: 1000,
          },
        }),
        //AgoraRTC.createScreenVideoTrack()
      ]);

      // [uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
      //   // join the channel
      //   client.join(appID, channelName, token),
      //   // create local tracks, using microphone and camera
      //   AgoraRTC.createMicrophoneAudioTrack(),
      //   AgoraRTC.createCameraVideoTrack({
      //     "facingMode":"environment",
      //     encoderConfig: {
      //       width: Utl.CONF.defaultWidth,
      //       // Specify a value range and an ideal value
      //       height: Utl.CONF.defaultHeight,
      //       frameRate: 30,
      //       bitrateMin: 600, bitrateMax: 1000,
      //     },
      //   }),
      //   //AgoraRTC.createScreenVideoTrack()
      // ]);

      console.log(role,uid,appID, channelName, token)

      Dp.publish = async ()=>{
        // play local video track
        localTracks.videoTrack.play("local-player");

        // publish local tracks to channel
        await client.publish(Object.values(localTracks));
        setState(Math.random());
      };
      // localTracks.videoTrack.play("local-player");

      // var video = document.createElement("video");//querySelector('video')
      // document.body.appendChild(localTracks.videoTrack);

      init = false;
      timer();
      setState(Math.random());

    }else{
      client.setClientRole(role, {level: 2});
      // add event listener to play remote tracks when remote user publishs.
      client.on("user-published", handleUserPublished);
      // on("channel-media-relay-event")
      client.on("user-unpublished", ()=>{
        Dp.remoteVideoUser = "";
        // Dp.remoteVideoTrack = "";
        if(timerObj)clearInterval(timerObj);
        setState(Math.random());
      });

      // join the channel
      uid = await client.join(appID, channelName, token);
      console.log(role,uid,appID, channelName, token)
      init = false;
    }

    async function handleUserPublished(user, mediaType) {
      // subscribe to a remote user
      await client.subscribe(user, mediaType);
      console.log("subscribe success");
      if (mediaType === 'video') {
        // play local video track
        const res = user.videoTrack.play("local-player", {fit:"contain"});
        console.log(res);

        // const video = document.getElementsByClassName( "agora_video_player" );
        // Dp.remoteVideo = video;
        // Dp.remoteVideoObj = video.srcObject;
        Dp.remoteVideoUser = user;
        // Dp.remoteVideoTrack = user._videoTrack._mediaStreamTrack;
        // if(!Dp.remoteVideoStream)Dp.remoteVideoStream = new MediaStream();
        // Dp.remoteVideoStream.addTrack(Dp.remoteVideoTrack);
        Dp.snap.obj.initVals.currentMov(Dp.snap.val.initVals.currentMov)

        setState(Math.random());
        // agora_video_player
        timer();
      }
      if (mediaType === 'audio') {
          // user.audioTrack.play();
      }
    }
  }


  var data = atob(json);
  data = unescape(data);
  data = decodeURIComponent(data);
  const txt = data;

  try {

    //例外エラーが発生するかもしれない処理
    data = JSON.parse(data);

} catch( e ) {

    //例外エラーが起きた時に実行する処理

}

  function rend(msg){
    if(header){
      document.title = Utl.json2url(data);

      return (
        <React.Fragment>
        {header}
          <Grid container justify="center" alignItems="center" direction="row" className={classes.root}>
          <Grid className={classes.caption}>
          {msg}
          </Grid>
          </Grid>
        </React.Fragment>
      )
    }else{
      return (
          <Grid container justify="center" alignItems="center" direction="row">
            {msg}
          </Grid>
      )
    }
  }

  if(typeof data == "object"){
    console.log(data);

    var frames = [];
    if(data.iframe) frames = data.iframe;

    if(frames[0].type=="webRTC"){
      if(state == "checkout"){
        return rend((
          <Grid className={classes.caption}>
              <Typography p={2} component="h2" variant="h5" gutterBottom>
                この機能を利用するには、License契約が必要です。
              </Typography>
              <br/>
              <Button variant="contained" color="primary"　onClick={()=>{window.location.href = window.location.origin+"/#/login/cmd/Mypage"}} className={classes.button}>
                購入ページへ
              </Button>
            </Grid>
        ));

      }else if(init) {
        agora(frames[0].role,user.uid);
        return rend((
                <Grid className={classes.caption}>
                  <CircularProgress disableShrink />
                </Grid>
              ));

      }else if(frames[0].role == "host"){
        return rend((
          <React.Fragment>
              <Grid className={classes.caption}>
              <Button variant="contained" color="primary"　onClick={Dp.publish} className={classes.button}>
                配信を開始
              </Button>
              <br/>
              <br/>
              <Button variant="contained" color="primary"　onClick={()=>{window.location.href = url}} className={classes.button}>
                再接続
              </Button>
              <br/>
              <br/>
                <div id="local-player" className={classes.video}></div>
              </Grid>
          </React.Fragment>
        ));

      }else{
        const a = (<div id="local-player" className={classes.video2} key="local-player"></div>);
        const b = (
          <>
          <div id="local-player" className={classes.video2} key="local-player"></div>
          <div className={classes.caption}>
          <Typography p={2} component="h3" variant="h6" gutterBottom>
            QRコードからスマホカメラ入力
          </Typography>
            <img src={qrc} alt="qr-code" className={classes.qr}/>
            { // We need to mount the Decoder component only after the user info became available.
            (navigator.clipboard) ?
              <Button variant="contained" color="primary"　onClick={()=>{
                if(navigator.clipboard){
                     navigator.clipboard.writeText(url);
                 }
              }} className={classes.button}>
                リンクをコピー
              </Button>
                :url
            }
          </div>
        </>
      );

        return rend
        ((
          <>
              {(Dp.remoteVideoUser)?a:b}
          </>
        ));
      }


    }else{
      const loaded = (e) => {
        console.log(e);
        const textReplace = e.target.contentWindow.document.querySelector('#textReplace');
        textReplace.innerHTML = frames[0].text;//'iframeの外のボタンが<br>クリックされました！！';
      }

    //{"iframe":[{"url":"http://localhost:3000/Resources/Custompage/text-with-background-image-gsap-animation/dist/index.html","text":"test test <br>test test"}]}
    //http://localhost:3000/#/login/cmd/Custompage%2FJTI1N0IlMjUyMmlmcmFtZSUyNTIyJTI1M0ElMjU1QiUyNTdCJTI1MjJ1cmwlMjUyMiUyNTNBJTI1MjJodHRwJTI1M0ElMjUyRiUyNTJGbG9jYWxob3N0JTI1M0EzMDAwJTI1MkZSZXNvdXJjZXMlMjUyRkN1c3RvbXBhZ2UlMjUyRnRleHQtd2l0aC1iYWNrZ3JvdW5kLWltYWdlLWdzYXAtYW5pbWF0aW9uJTI1MkZkaXN0JTI1MkZpbmRleC5odG1sJTI1MjIlMjUyQyUyNTIydGV4dCUyNTIyJTI1M0ElMjUyMnRlc3QlMjUyMHRlc3QlMjUyMCUyNTNDYnIlMjUzRXRlc3QlMjUyMHRlc3QlMjUyMiUyNTdEJTI1NUQlMjU3RA==
    //http://localhost:3000/#/login/cmd/Custompage%2Feditor
      return (
        <React.Fragment>
            <Iframe className={classes.frame}
                id = {'frame1'}
                url = {frames[0].url}
                onLoad={loaded}/>
        </React.Fragment>
      );
  }

  }else{
    return rend((
      <div>
        <Typography p={2} component="h2" variant="h5" gutterBottom>
          パラメータをJSONで入力してください。
        </Typography>
       <TextField
         id={"json"}
         placeholder={"{} //JSON"}
         defaultValue={'{"iframe":[{"type":"webRTC","role":"audience"}]}'}
         // defaultValue={'{"iframe":[{"url":"http://localhost:3000/Resources/Custompage/text-with-background-image-gsap-animation/dist/index.html","text":"test test <br>test test"}]}'}
         label="Json"
         multiline
         rows={4}
         variant="outlined"
       />
       <br/>
       <br/>

       <Button variant="contained" color="primary"　onClick={()=>{
         var s = document.getElementById('json').value
         try{
           s = JSON.parse(s);
           window.location.href = Utl.json2url(s)
         }catch{
           window.location.href = "http://localhost:3000/#/login/cmd/Custompage%2F"+s
         }
       }} className={classes.button}>
         CustomPageを開く
       </Button>
      </div>
    ));
  }
}

Custompage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Custompage);
