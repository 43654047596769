import Axios from 'axios';
import CONF from './config';
import Cookies from 'js-cookie';

const web = !window.api;
const Utl = {
  folder:path => {return path.match(/[^/]+$/)},
  filename:path => {return path.match(/([^/]*)\./)[1]},
  extend:path => {return path.match(/[^.]+$/)},
  CONF:CONF,
  CookiesSet:(val,key)=>{
    window.localStorage.setItem(key,JSON.stringify(val));
    // Cookies.set(key, val , {expires: 9999});
  },
  CookiesGet:(key)=>{
    const res = window.localStorage.getItem(key);
    if(res)return JSON.parse(res);

    // return Cookies.getJSON(key);
    // const res = Cookies.getJSON('designpad');
    // if(typeof res == "object") return res[key];
  },
  AxiosWrap:Axios,
  Axios:Axios,
  // 指定セレクタのCSSルールを取得する
  // 呼び出し例　getRuleBySelector(".inner1")   seleにCSSセレクタ
  getRuleBySelector:(sele) => {
      var i, j, sheets, rules, rule = null;

      // stylesheetのリストを取得
      sheets = document.styleSheets;
      for(i=0; i<sheets.length; i++){
          // そのstylesheetが持つCSSルールのリストを取得
          rules = sheets[i].cssRules;
          for(j=0; j<rules.length; j++){
              // セレクタが一致するか調べる
              if(sele === rules[j].selectorText){
                  rule = rules[j];
                  break;
              }
          }
      }
      return rule;
  },
}

Utl.wins = {};


// const test = [
//   "https://player.twitch.tv/?channel=hotbeatstv&parent=localhost",
//   "https://player.twitch.tv/?channel=alleykaydj&parent=localhost",
//   "https://player.twitch.tv/?channel=djfranzen&parent=localhost",
//   "https://player.twitch.tv/?channel=jessutv&parent=localhost",
// https://www.youtube.com/embed/DY_rFed96mg?autoplay=1
//https://www.youtube.com/embed/GQhNwlibeP8?autoplay=1&mute=1&playsinline=1&loop=1&playlist=GQhNwlibeP8
// ]
//
//
Utl.CookiesSet("",'SampleconfJson')
console.log(Utl.CookiesGet("SampleconfJson"))

var views = [];

Utl.LastDesktopCapturer = [];
Utl.desktopCapturer = async (func) => {
  await Axios.get("/setWindowUrl/");

  Utl.LastDesktopCapturer = await window.api.desktopCapturer.getSources({ types: ['window', 'screen'] });

  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: Utl.LastDesktopCapturer[0].id,
        }
      }
    })
    // localStream = stream;
    // handleStream(stream)
  } catch (e) {
    console.log(e)
  }

  Utl.LastDesktopCapturer = await window.api.desktopCapturer.getSources({ types: ['window', 'screen'] });
}

// Utl.base64Encode = async (...parts) => {
//   return new Promise(resolve => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const offset = reader.result.indexOf(",") + 1;
//       resolve(reader.result.slice(offset));
//     };
//     reader.readAsDataURL(new Blob(parts));
//   });
// }
//
// Utl.base64Decode = async (text) => {
//   return fetch(`data:text/plain;charset=UTF-8;base64,` + text).then(response => response.text());
// }
Utl.json2param = (json) => {
  json = JSON.stringify(json);
  return btoa(escape(encodeURIComponent(json)));
}

Utl.json2url = (json,domain) => {
  if(domain) json = domain + "/#/login/cmd/Custompage%2F" + Utl.json2param(json) ; //Utl.base64Encode(s.url);
  else json = "https://localhost:3000/#/login/cmd/Custompage%2F" + Utl.json2param(json); //Utl.base64Encode(s.url);
  return json;
}

Utl.jsonCopy = (j) => {
  if(typeof j == "object") return JSON.parse(JSON.stringify(j));
  else return j;
}

Utl.getElementFromSheet = (s) => {
  document.body.childNodes.forEach((e, i) => {
    if(e.style == s)return e;
  });
}

// Utl.previewWeb = {};
Utl.ctrlWindow = async (prop)=>{
  const id = prop.id;
  var s = {
    id:id,
    prop:prop
  };

  if(prop.url == "camera")
  
   return;

  else if(prop.url){
    s = prop;

  }else{
    if(id){
      s.url = document.getElementById('inputBase'+id).value
      // if(s.url.substr(0,4) != "http")s.url = "https://"+s.url;
      if(s.url.substr(0,4) != "http"){
        s.url = JSON.parse(s.url);
        s.url = Utl.json2url(s.url);

        // let str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        // s.url.id = '';
        //
        // for(let i=0; i<10; i++){
        //     s.url.id += str[~~(Math.random() * str.length)];
        // }

      }
    }
  }


  if(s.prop.visible == "show"){
    s.x = (window.screen.width - CONF.defaultWidth)/2
    s.y = (window.screen.height - CONF.defaultHeight)/2
    s.w = CONF.defaultWidth
    s.h = CONF.defaultHeight
    views.push(Utl.jsonCopy(s));

    // console.log(s)
    await update(s);

    return s.url;

  }else if(s.prop.visible == "hide"){
    s.x = 0
    s.y = window.screen.height-1
    // s.w = CONF.defaultWidth
    // s.h = CONF.defaultHeight
    views.map(async (view)=>{
      s.x += 10
      s.url = view.url;
      s.id = view.id;
      await update(s)
    })
    views = [];

  }else if(s.prop.visible == "close"){
    s.url = "";
    await update(s)
  }


  async function update(s){
    console.log(s)
    if(web){
      if(s.url == ""){
        if(Utl.wins[s.id])Utl.wins[s.id].win.close();

      }else if(s.prop.visible == "hide"){
        // Utl.wins[s.id].minimize();
      }else{
        Utl.wins[s.id] = {
          win:window.open(s.url,s.id,"top="+s.y+",width="+s.w+",height="+s.h+",left="+s.x+",location=0,status=0,resizable=0"),
          obj:s
        };
      }

    }else{
      await Axios.get("/ctrlWindow/"+encodeURIComponent(JSON.stringify(s)));
    }

    //Save For Bank
    if(s.url == ""){
      if(Utl.wins[s.id]) Utl.wins[s.id] = false;
    }else{
      Utl.wins[s.id] = {obj:s,...Utl.wins[s.id]};
    }

    // if(s.prop.visible == "show") Utl.previewWeb = Utl.jsonCopy(Utl.Dp.snap.val.initVals.currentWeb);
    Utl.Dp.snap.val.initVals.currentWeb = {};
    Object.keys(Utl.wins).forEach(function (key) {
      if(Utl.wins[key]){
        Utl.Dp.snap.val.initVals.currentWeb[key] = Utl.wins[key].obj;
      }
    });
  }
  // }else{
  //   const w2 = Utl.DP.windowsSub.width/2;
  //   const h2 = Utl.DP.windowsSub.height/2;
  //   s.x = Utl.DP.windowsSub.x+(w2*prop.w)
  //   s.y = Utl.DP.windowsSub.y+(h2*prop.h)
  //   s.w = w2
  //   s.h = h2
  // }


  // if(prop.type == "iframe"){
  //   if(s.url == ""){
  //     if(wins[id])wins[id].remove();
  //
  //   }else{
  //     if(!wins[id])wins[id] = document.createElement("iframe");
  //     if(wins[id].src != s.url) wins[id].src = s.url;
  //
  //     document.getElementById("ui-wrapper").append(wins[id]);
  //     wins[id].style["z-index"] = 1200;
  //     wins[id].style["background-color"] = "#000";
  //     wins[id].style.position = "fixed";
  //     wins[id].style.left = s.x+"px";
  //     wins[id].style.top = s.y+"px";
  //     wins[id].style.width = s.w+"px";
  //     wins[id].style.height = s.h+"px";
  //   }
  //
  // }else
}
Utl.ctrlWindowClose = ()=>{
  if(!web){
    Axios.get("/ctrlWindowClose");
  }
}

if(web){
  // Utl.iFrame = (id,w,h)=>{
  //   const s = getBonds(id,w,h);
  //   window.open(s.url,id,"top="+s.y+",width="+s.w+",height="+s.h+",left="+s.x+",location=1,status=0");
  // }

  Utl.AxiosWrap =
    {
      get:async(url)=>{
        if(url.match(/openExternal/)){
          window.open("https://"+url.split("/openExternal/")[1]);

        }else if(url == "/fullscreenOn"){
          const w = window.innerWidth/2;
          return {data:[
            {bounds:{x:0,y:0,width:w,height:window.innerHeight}},
            {bounds:{x:w,y:0,width:w,height:window.innerHeight}}
          ]};

        }else console.log("Ignore:"+url);
      }
    };
}

export default Utl;
