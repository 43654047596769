import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  margin: {
    marginRight: '2px',
  },
  drag:{
    "pointer-events": "none",
  },
});

// const options = [
//   'chA 100%',
//   'chB 100%',
// ];

const ITEM_HEIGHT = 48;

const RTFx = props => {
  const { classes, callback ,Dp} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const onMouseDown = (event) => {
    //callback.RTFx.func({RTFx:callback.file})
    console.log(callback)
    Dp.rtfxFunc({pos:{x:event.clientX,y:event.clientY},rtfx:callback.file});
    // Dp.channel.postMessage({massage:"setRTFx",param:{pos:{x:event.clientX,y:event.clientY},rtfx:callback.file}})
  }

  // const handleClick = (event) => {
  //   var el = document.getElementById(uid);
  //   console.log(el)
  //   setAnchorEl(el);//event.currentTarget);
  // };


  // const handleClose = (label) => {
  //   if(label) Dp.func({ch:label,RTFx:callback.file,opacity:1.0})
  //   setAnchorEl(null);
  // };

  const uid = "menu"+Math.random();

  return (
    <div>
      <Grid item className={classes.margin}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          key={"rtfx"+callback.file.thumb[0]+callback.file.thumb[1]}
        >
        <CardActionArea
          id = {uid}
          onMouseDown={onMouseDown}
        >
        <img className={classes.drag} src={"/Resources/Extention/thumb,"+callback.file.thumb[0]+callback.file.thumb[1]+".png"} alt={callback.file} />
        </CardActionArea>
      </Grid>
    </div>
  );
}

RTFx.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RTFx);
