import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Selects(props) {
  const classes = useStyles();
  const { callback,def,list } = props;
  const [state, setState] = React.useState(def);

  const handleChange = (event) => {
    callback(event.target.value)
    setState(event.target.value);
    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state}
        onChange={handleChange}
      >
      {list.map((val)=>(
          <MenuItem value={val}>{val}</MenuItem>
      ))}
      </Select>
    </div>
  );
}
