import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
//import Slider from '@material-ui/core/Slider';
import NestedMenuItem from "./nestedItem";

import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';

const label = {
  "material 1":"Ch1",
  "material 2":"Ch2",
  "material 3":"Ch3",
  "material 5":"Ch4",
}
const label2 = {
  "material 1":"chA",
  "material 2":"chC",
  "material 3":"chB",
  "material 5":"chDJ",
}
const label3 = {
  "material 1":" 1",
  "material 2":" 2",
  "material 3":" 3",
  "material 5":" 5",
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  margin: {
    marginRight: '2px',
  },
});

// const options = [
//   'chA 100%',
//   'chB 100%',
// ];

const ITEM_HEIGHT = 48;

const Mixer = props => {
  const { classes, ch ,Dp} = props;

  // const [value, setValue] = React.useState(0);//callback.obj[callback.key]);
  const [thumb, setThumb] = React.useState("/img/noimage.png");//callback.obj[callback.key]);

  const handleChange = (event) => {
    console.log({cat:"material",lyr:label3[ch],keys:{"fader":Number(event.target.value)}});
    Dp.channel.postMessage({massage:"setFxDirect",param:{cat:"material",lyr:label3[ch],keys:{"fader":Number(event.target.value)}}})
    Dp.snap.val.initVals.currentMixer[label3[ch]] = Number(event.target.value);

    //Dp.channel.postMessage({massage:"func",param:{name:ch,func:"opacity",value:Number(val)}})
    //Dp.snap.obj[ch].opacity(Number(val));
  };

  const delClick = async () => {
    // console.log(callback);
    if(label) Dp.channel.postMessage({massage:"setMov",param:{ch:label2[ch],mov:"",opacity:0}})
    // Dp.channel.postMessage({massage:"func",param:{name:ch,func:"opacity",value:0}})
    //await callback.Mov.func({ch:ch,opacity:0})
  };


    React.useEffect(()=>{
      Dp[ch] = document.getElementById(uid);//handleChange;
      Dp[label2[ch]+"thumb"] = setThumb;

      // console.log(callback);
      // if(Dp.windowtype=="main"){
        Dp.channel.postMessage({
          massage:"setFxDirect",
          param:{
            cat:"material",
            lyr:label3[ch],
            keys:{"fader":1}
          }
        })

    }, []);

  //
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  //
  // const handleClick = (event) => {
  //   var el = document.getElementById(uid);
  //   console.log(el)
  //   setAnchorEl(el);//event.currentTarget);
  // };
  //
  //
  // const handleClose = (label) => {
  //   if(label) Dp.func({ch:label,mov:callback.file})
  //   setAnchorEl(null);
  // };
  //
  //


  const [menuPosition, setMenuPosition] = React.useState(null);

  const handleRightClick = async (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });

    // await handleClickGetDisp();
  };

  const handleItemClick = () => {
    setMenuPosition(null);
  };


  const uid = "menu"+Math.random();

  return (
    <div>
    <ListItem key={"Mixer"+ch}>
      <Grid container spacing={1} text-align={"center"}>
      <Grid item>
        <ListItemText id="switch-list-label-bluetooth" primary={label[ch]} />
      </Grid>
      <Grid item>
        <CardActionArea onClick={handleRightClick}>
          <img src={thumb} />
        </CardActionArea>
      </Grid>
       <Grid item xs>
       <input
         id = {uid}
         className="range"
         type="range"
         // value={value}
         onChange={handleChange}
         step="0.01"
         min="0"
         max="1.0"
         style={{
           "position": "relative",
           "color": "#2196f3",
           "width": "100%",
           "cursor": "pointer",
           "height": "2px",
           "display": "inline-block",
           "padding": "11px 0",
           "position": "relative",
         }}
       />
      </Grid>
      {/*
       <Grid item>
         <IconButton edge="end" aria-label="delete" className={classes.margin} size="small" onClick={delClick}>
          <DeleteIcon fontSize="inherit"/>
        </IconButton>
       </Grid>
       */}
     </Grid>
   </ListItem>

   </div>
  );
}

Mixer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mixer);
