import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(8),
    width:"100%",
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  paypal: {
    display: "none",
  },
});


const Mypage = props => {
  const { classes, user , CONF } = props;

  const buy = (event) => {
    var el = document.getElementById( 'paypal1' )
    el.click();
  };

  function open(){
    // window.open( CONF.paypal.url+'myaccount/autopay/connect/'+user.subscr_id );
    const callback = CONF.firebase.functions+"/portal?custamer_id="+user.custamer_id;
    console.log(callback);
    // window.open( callback );
    window.location.href = callback;
  }

  var pp_stats = (<div>
                  <Button variant="contained" color="primary"　onClick={()=>{Axios.get("/openExternal/"+CONF.paypal.web);}}>
                    ライセンス確認
                  </Button>
                  <br />
                  <br />
                  <Link href="" onClick={()=>{window.location.reload()}}>
                   戻る
                  </Link>
                </div>
              );

  var app = (<div>
                <Typography p={2} component="h4" variant="h6" gutterBottom>
                  商用ライセンスをお持ちでない方は、下記のWEBサイトをご確認ください。
                </Typography>
                {pp_stats}
              </div>);


  if(CONF.web){
    // app = (<div>
    //         <Typography p={2} component="h4" variant="h6" gutterBottom>
    //           期間限定で「早期特別優待50%OFF」にて販売中！<br/>
    //           年<del>29,600円</del> → <b>14,800円(※14日間無料)</b>
    //         </Typography>
    //         <Button variant="contained" color="primary"　onClick={buy}>
    //           購入ページへ
    //         </Button>
    //       </div>);

    app = (<div>
            <Typography p={2} component="h4" variant="h6" gutterBottom>
              期間限定で「早期特別優待価格」にて販売中！<br/>
              €25/月
            </Typography>
            <Button variant="contained" color="primary"　onClick={()=>{
              const callback = CONF.firebase.functions+"/checkout?userId="+user.uid+"&priceId="+CONF.stripe.price+"&priceId2="+CONF.stripe.price2+"&url=Mypage";
              console.log(callback);
              window.location.href = callback;
            }} className={classes.button}>
              購入ページへ
            </Button>
          </div>);



          // {"(Paypal状況　請求ID:"+user.subscr_id+")"}

    pp_stats = (<div>
        <Link href="#" onClick={open}>
        {"(ラインセンスの管理)"}
        </Link>
        <br />
        <br />
        <br />
        <br />
        <Grid className={classes.caption}>
          <Typography component="h2" variant="h5" gutterBottom>ライセンスの認証が完了しました。</Typography>
          <Typography gutterBottom>対象OSのアプリを起動することで、ご利用頂くことが可能です。</Typography>
        </Grid>
      </div>);
  }

  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/mypage" Dp={{}}/>
      <Grid container justify="center" alignItems="center" direction="row" className={classes.root}>
        <Grid className={classes.caption}>
        { // We need to mount the Decoder component only after the user info became available.
        // (user.txn_type　!= "subscr_payment" && user.txn_type　!= "subscr_signup") ?
        (user.eventType != "invoice.paid") ?
          <div>
            <Alert style={{
                pudding: "10"
              }}
              severity="warning">ご購入後、商用ライセンスが反映されるまで、数時間かかる場合があります。
            </Alert>
            <br />
          </div>
            :""
        }
        <Typography p={2} component="h2" variant="h5" gutterBottom>
        { // We need to mount the Decoder component only after the user info became available.
          (user.eventType) ?
            (user.eventType== "customer.subscription.deleted")?
              "状態：キャンセル済"//+user.txn_type
              :"状態：定期購読中"//+user.txn_type
            : (user.data == "error") ? "トライアル期限が終了しました。":"トライアル期間中"
        }
        </Typography>
        { // We need to mount the Decoder component only after the user info became available.
          // (user.txn_type　== "subscr_payment" || user.txn_type　== "subscr_signup") ?
          (user.eventType == "invoice.paid") ?
            pp_stats
            :app
        }
        </Grid>
      </Grid>
      <form action={CONF.paypal.url+"cgi-bin/webscr"} method="post" target="_top" className={classes.paypal}>
        <input type="hidden" name="cmd" value="_xclick-subscriptions"/>
        <input type="hidden" name="business" value={CONF.paypal.id}/>
        <input type="hidden" name="lc" value="JP"/>
        <input type="hidden" name="item_name" value={CONF.info.name +" "+ CONF.paypal.item}/>
        <input type="hidden" name="no_note" value="1"/>
        <input type="hidden" name="no_shipping" value="1"/>
        <input type="hidden" name="rm" value="2"/>
        <input type="hidden" name="return" id="paypal_return" value={"http://"+window.location.host+"/#/login/target/mypage"}/>
        <input type="hidden" name="cancel_return" id="paypal_return" value={"http://"+window.location.host+"/#/login/target/mypage"}/>
        <input type="hidden" name="a1" value={CONF.paypal.a1}/>
        <input type="hidden" name="p1" value={CONF.paypal.p1}/>
        <input type="hidden" name="t1" value={CONF.paypal.t1}/>
        <input type="hidden" name="src" value="1"/>
        <input type="hidden" name="a3" value={CONF.paypal.a3}/>
        <input type="hidden" name="p3" value={CONF.paypal.p3}/>
        <input type="hidden" name="t3" value={CONF.paypal.t3}/>
        <input type="hidden" name="currency_code" value="EUR"/>
        <input type="hidden" name="bn" value="PP-SubscriptionsBF:btn_subscribeCC_LG.gif:NonHosted"/>
        <input type="hidden" name="notify_url" id="paypal_ipn" value={CONF.paypal.ipn}/>
        <input type="hidden" name="custom" id="paypal_user_id" value={user.uid}/>
        <input type="image" id="paypal1" src={CONF.paypal.url+"ja_JP/JP/i/btn/btn_subscribeCC_LG.gif"} border="0" name="submit" alt="PayPal - オンラインでより安全・簡単にお支払い"/>
        <img alt="" border="0" src={CONF.paypal.url+"ja_JP/i/scr/pixel.gif"} width="1" height="1"/>
      </form>
    </React.Fragment>
  );
}

Mypage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mypage);
