import React, { useState, useEffect } from 'react';
//import { Route, BrowserRouter as Router } from 'react-router-dom';
import { HashRouter, Route, Switch ,Redirect} from "react-router-dom";
// import Axios from 'axios';
import Utl from './Utl';
import Core from './Core';

import CssBaseline from '@material-ui/core/CssBaseline';
//import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme';
import Home from './Home';
import Mypage from './Mypage';
import Custompage from './Custompage';

import Login from './Login';
// import Login from './Log-in';
import Decoder from './Decoder';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import CONF from './config';

//
// var CONF = process.env.REACT_APP_API_ENDPOINT;
// // console.log(process.env);
//
// if(CONF){
//   CONF = JSON.parse(CONF);
// }else{
//   CONF = config;
// }
// CONF.web = false;
// // CONF.web = (!window.api);// && CONF.webdev == "webdev");
// console.log(CONF);

firebase.initializeApp(CONF.firebase);
const db = firebase.firestore();

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = firebase.storage();

// Create a storage reference from our storage service
const storageRef = storage.ref();

const Dp = Core();

function App() {
  const [user, setUser] = useState(0);

  useEffect(()=>{
    document.addEventListener('keydown', event => {
        // 変数eventの中身はKeyboardEventオブジェクト
        // console.log(event);
        if (event.key == '0' && event.shiftKey) {
           Utl.AxiosWrap.get("/openDevTools");

        }
    });

    // function script(path){
    //   const head = document.getElementsByTagName('head')[0];
    //   const scriptUrl = document.createElement('script');
    //   scriptUrl.type = 'text/javascript';
    //   scriptUrl.src = path;//'js/index.js';
    //   head.appendChild(scriptUrl);
    // }
    // script('js/index.js');
    // script('js/shader.js');

    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (userValue) => {
        console.log(userValue);

        if (userValue) {
          //------------------------------------
          // メアド確認済み
          //------------------------------------
          if (userValue.emailVerified) {
            const result = await firebase.auth().currentUser.getIdTokenResult(true);
            console.log(result.claims);

            const refUser = db.collection("users").doc(userValue.uid);
            const doc = (await refUser.get()).data();
            console.log(doc);

            if(doc){
              if(doc.data){
                userValue.txn_type = doc.txn_type;
                userValue.eventType = doc.eventType;
                userValue.subscr_id = doc.subscr_id;
                userValue.custamer_id = doc.custamer_id;


                try {
                  const url = await storageRef.child("license_base/data0.2.0.json").getDownloadURL();//.then(async function(url) {
                  // const url = await storageRef.child(doc.data).getDownloadURL();//.then(async function(url) {
                  // `url` is the download URL for 'images/stars.jpg'

                  console.log(url);

                  const res = await Utl.Axios.get(url);
                  const items = res.data;
                  userValue.data = res.data;

                  // const d = await Utl.Axios.get("/Resources/shader.js");
                  // userValue.data.fragmentShader = encodeURI(d.data);

                  console.log(res);
                  // console.log(d);

                } catch (error) {
                  userValue.data = "error";
                  console.log(error);
                }

                //
                // // This can be downloaded directly:
                // var xhr = new XMLHttpRequest();
                // xhr.responseType = 'blob';
                // xhr.onload = function(event) {
                //   var blob = xhr.response;
                // };
                // xhr.open('GET', url);
                // xhr.send();
                //
                // // Or inserted into an <img> element:
                // var img = document.getElementById('myimg');
                // img.src = url;
              // }).catch(function(error) {
              //   // Handle any errors
              // });

              }

            }else{
              userValue = null;
            }

            // const refUser = db.collection("users").doc(userValue.uid);
            // //const newValue = { lastAccessed:firebase.firestore.FieldValue.serverTimestamp() };
            // const doc = (await refUser.get()).data();
            //
            // if(doc.data){
            //   var json = await Utl.AxiosWrap.get(doc.data)
            //   console.log(json);
            // }

            // if (!doc || !doc.name) {
            //   newValue.name = userValue.displayName;
            // }
            // await refUser.set(newValue, { merge: true });

          }
          //------------------------------------
          // メアド未確認
          //------------------------------------
          else {
              userValue.sendEmailVerification()
                  .then(() => {
                    //window.history.pushState(null, null, '#/Sendmail/ok');
                    //return <Redirect to="/Sendmail"/>
                      //alert('${user.email}宛に確認メールを送信しました');
                      //showMessage('Send confirm mail', '${user.email}宛に確認メールを送信しました');
                  })
                  .catch((error) => {
                    setUser(null);
                      //window.history.pushState(null, null, '#/Sendmail/ng');
                      // alert('${user.email}宛に確認メールを送信できませんでした');
                      //showMessage('[Error] Can not send mail', `${user.email}宛に確認メールを送信できませんでした: ${error}`);
                  });
            }
          }
          setUser(userValue);
      }
    );

    return unregisterAuthObserver;
  }, []);

  const params = { user, db , storage , CONF , Utl , Dp};

  function PrivateRoute(props) {
    // 渡された props をそのまま Route に設定する
    return user ? <Route {...props} /> : <Redirect to="/login" />;
  }

  // function GuestRoute(props) {
  //   // 渡された props をそのまま Route に設定する
  //   return user ? <Redirect to="/" />:<Route {...props} /> ;
  // }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
      <Switch>
        <Route exact  path="/login" render={(props) => <Login {...props} {...params} />} />
        <Route exact  path="/login/cmd/:encoded" render={(props) => <Login {...props} {...params} />} />
        <Route exact  path="/login/target/:target" render={(props) => <Login {...props} {...params} />} />
        { // We need to mount the Decoder component only after the user info became available.
          (user) ?
            <Route exact path="/decode/:encoded" render={(props) => <Decoder {...props} {...params} />} />
            : ""
        }

        <PrivateRoute path="/Mypage" children={(props) => <Mypage {...props} {...params} />} />
        <PrivateRoute path="/Custompage/:json" children={(props) => <Custompage {...props} {...params} />} />
        <PrivateRoute path="/Home" children={(props) => <Redirect to="/" />} />
        <PrivateRoute path="/:param" children={(props) => <Home {...props} {...params} />} />
        <PrivateRoute path="/" children={(props) => <Home {...props} {...params} />} />

        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
