// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  info:{
    name:"withSparks",
    version:"0.3.4",
    env:"staging",
  },
  firebase:{
    apiKey: "AIzaSyDTfnSYJZwKTg4V_q7WH6lXcps1FTK0s84",
    authDomain: "dp-xr-42ec4.firebaseapp.com",
    projectId: "dp-xr-42ec4",
    storageBucket: "dp-xr-42ec4.appspot.com",
    functions:"https://us-central1-dp-xr-42ec4.cloudfunctions.net",
    messagingSenderId: "97726209962",
    appId: "1:97726209962:web:9f236b4a4e3b2cbaa915d3",
    measurementId: "G-DSXNN8F39G",
    web:"sparks.ee"
  },
  agora:{
    id:"5d6bcfa377fe402c93dcba049391a117",
    usage:1000 * 60 * 10,
  },
  stripe:{
    price:"price_1JyFlJKvm9IQapEjMuh8SqbN",
    price2:"price_1JyFkhKvm9IQapEjVgDcIi9N",
  },
  paypal:{
    web:"shop.uniteddesign.org",
    url:"https://www.paypal.com/",
    ipn:"https://us-central1-dp-xr-42ec4.cloudfunctions.net/ipnHandler",
    item:"Licence",
    id:"CWLL85KDAV96J",//"YWKJB8E4XQT3J",

    a1:"0",
    p1:"1",
    t1:"W",
    a3:"120",
    p3:"1",
    t3:"Y",
  },
};


var CONF = process.env.REACT_APP_API_ENDPOINT;
console.log(process.env);

if(CONF){
  CONF = JSON.parse(CONF);
}else{
  CONF = config;
}

CONF.defaultWidth = 848;
CONF.defaultHeight = 480;

CONF.defaultTextureWidth = 848;
CONF.defaultTextureHeight = 480;

CONF.web = (!window.api);
// CONF.web = false;//コメントアウトで本番化
// CONF.firebase.functions = "http://localhost:5001/dp-xr-staging/us-central1";

console.log(CONF);

export default CONF;
