import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ColorPicker, createColor } from "material-ui-color";

const styles = theme => ({});

const Color = props => {
  const { callback,def } = props;
  const [color, setColor] = React.useState(createColor(def));
  const handleChange = (value) => {
    console.log("onChange=", value);
    setColor(value);
  };

  return (
    <React.Fragment>
      <ColorPicker disableAlpha
        value={color}
        onChange={(v)=>{
          handleChange(v)
          console.log(v)
          callback(v.value)
        }}
      />
    </React.Fragment>
  );
}

export default Color;
