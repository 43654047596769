import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: "#222222",
    width:"100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function MyAppBar(props) {
  const { classes, user, login ,style,Dp,CONF} = props;
  const [drawer, setDrawer] = useState(false);

  const history = useHistory();

  const handleMenu = event => {
    setDrawer(true);
  };
  const handleClose = () => {
    setDrawer(false);
  };
  const logout = event => {
    console.log("logout");
    firebase.auth().signOut();
    history.push('/')
    // firebase.auth().signOut().then((history) => {
    //     window.history.pushState(null, null, '#/');
    // });
  };



  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = async (val) => {
    console.log(val);

    if(val.id) var title = val.id;
    else var title = val.currentTarget.getAttribute("title")

    if(title){
      var func = menu.find(element => element.title == title);
      if(val.func)await val.func();
      else await func.click();

      //console.log(val.currentTarget);
    }
    setAnchorEl(null);
    if(title=="ライセンス"){
      const s = document.getElementById("root").style;
      s.left = "";//win[0].x+"px";
      s.width = "100%";//(win[0].width-10)+"px";
    }else{
      Dp.OBJ.update(Math.random());
    }
  };

  const menuId = 'primary-search-account-menu';
  var renderMenu = "";
  var renderMenu2 = (
    <IconButton aria-label="display more actions" edge="end" color="inherit" onClick={handleProfileMenuOpen} size="small">
      <MoreIcon />
    </IconButton>
  )

  const [menu, setMenu] = React.useState([]);
  const [page, setPage] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState([]);

  const handleClickOpen2 = (menu) => {
    setDialog(menu);
    setOpen(true);
    Dp.OBJ.update(Math.random());
  };

  const handleClose2 = () => {
    setOpen(false);
  };


  if(Dp){
    Dp.setMenu = setMenu;
    Dp.setPage = setPage;
    Dp.HeaderMenu = {open:handleClickOpen2,close:handleClose2,setDialog:setDialog};

    renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
      {menu.map((val) => (
        val == "devider"?
          <Divider key={Math.random()}/>
          :
          <MenuItem onClick={handleMenuClose} title={val.title} key={Math.random()}>{val.title}</MenuItem>
      ))}

      {
          (page == "hideLogin_showmwnu")?"":
            user ?
            <MenuItem onClick={logout} >ログアウト</MenuItem>
            // <Button color="inherit" onClick={logout}>Logout</Button>
            // : <Button color="inherit" to={login || "/login"} component={Link}>Login</Button>
            : <MenuItem to={login || "/login"} component={Link}>ログイン</MenuItem>
      }

        <Dialog
           open={open}
           onClose={dialog[2]}
           aria-labelledby="customized-dialog-title"
         >
         <DialogTitle id="customized-dialog-title">{dialog[0]}</DialogTitle>
           {<DialogContent dividers>{dialog[1]}</DialogContent>}
           {
             dialog[3]?
             <DialogActions>
                <Button onClick={dialog[2]} color="primary">
                  {dialog[3]}
                </Button>
              </DialogActions>
              :""
           }
         </Dialog>
      </Menu>
    );
  }


  return (
    <div className={classes.root}>
      <AppBar style={style} className={classes.bar}>
        <Toolbar id="AppBarRoot" variant="dense">
        {/*
          (page == true)?
          <IconButton className={classes.menuButton} onClick={handleMenu} color="inherit" aria-label="Menu" size="small">
            <MenuIcon />
          </IconButton>
          :""
        */}
          <Typography variant="h6" color="inherit" className={classes.grow}>
            withSparks
          </Typography>
          {(page == "hideLogin")?"":renderMenu2}
        </Toolbar>
      </AppBar>

      {renderMenu}

      <Drawer open={drawer} onClose={handleClose}>
        <List>
          <ListItem button to="/Home" component={Link}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button to="/mypage" component={Link}>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="Mypage" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

MyAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyAppBar);
