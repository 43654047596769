import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';


// import React, { useState } from "react";
// import { Menu, MenuItem, Typography } from "@material-ui/core";

// import NestedMenuItem from "material-ui-nested-menu-item";
import NestedMenuItem from "./nestedItem";

// import Axios from 'axios';
import Utl from './Utl';


const data = {list:[],ids:[],label:null,webs:[]}


const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  margin: {
    marginRight: '2px',
  },
});

// const options = [
//   'chA 100%',
//   'chB 100%',
// ];

const ITEM_HEIGHT = 48;

const Mov = props => {
  const { classes, callback ,Dp } = props;

  const [openDialog, setOpen] = React.useState(false);
  const [img, setImg] = React.useState("/img/noimage.png");
  const [selectedValue, setSelectedValue] = React.useState("");

  // const handleClickOpenDialog = async () => {
  //   await window.api.desktopCapturer.getSources({ types: ['window', 'screen'] })
  //     .then(async sources => {
  //       data.list = [];
  //       data.ids = {};
  //       sources.forEach((value) => {
  //         data.list.push(value.name);
  //         data.ids[value.name] = value.id;
  //       })
  //     });
  //   setOpen(true);
  // };

  const handleCloseDialog = (value) => {
    setOpen(false);
    setSelectedValue(value);
    if(typeof data.label == "string") Dp.channel.postMessage({massage:"setMov",param:{ch:data.label,mov:callback.file,opacity:1.0,displayCapture:data.ids[value]}})
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    var el = document.getElementById(uid);
    console.log(el)
    setAnchorEl(el);//event.currentTarget);
  };


  const handleClose = async (label,value) => {
    console.log(callback.file);
    console.log(window.api.desktopCapturer);

//    setAnchorEl(null);
    setMenuPosition(null);
    if(callback.file == "displayCapture" && window.api.desktopCapturer) {

        // const desktopCapturer = await Utl.AxiosWrap.get("/desktopCapturer");
        // console.log(desktopCapturer);

        await Utl.desktopCapturer();
        Dp.HeaderMenu.open(
          ["ウィンドウ選択",
          Utl.LastDesktopCapturer.map((val)=>(
            <ListItem button onClick={() => {
                Dp.channel.postMessage({massage:"setMov",param:{ch:label,mov:callback.file,opacity:1.0,displayCapture:val.id,displayCaptureName:val.name}})
                Dp.HeaderMenu.close();
            }}>
              <ListItemText primary={val.name} />
            </ListItem>
          )),
          Dp.HeaderMenu.close,
          "Close"
        ])


        // Utl.LastDesktopCapturer.map((v)=>{
        //   const index = snap.val.oprnUrl.indexOf(v.name);
        //   if(index != -1) {
        //     const id = "Web"+(snap.val.oprnUrl.length - index);
        //     const prop = {
        //        id: id,
        //        prop: {id: id, visible: "close"},
        //        url: v.name
        //     }
        //     Utl.ctrlWindow(prop);
        //   }
        // })

        // await window.api.desktopCapturer.getSources({ types: ['window', 'screen'] })
        //   .then(async sources => {
        //     console.log(sources);
        //
        //     Dp.HeaderMenu.open(
        //       ["ウィンドウ選択",
        //       sources.map((val)=>(
        //         <ListItem button onClick={() => {
        //             Dp.channel.postMessage({massage:"setMov",param:{ch:label,mov:callback.file,opacity:1.0,displayCapture:val.id,displayCaptureName:val.name}})
        //             Dp.HeaderMenu.close();
        //         }}>
        //           <ListItemText primary={val.name} />
        //         </ListItem>
        //       )),
        //       Dp.HeaderMenu.close,
        //       "Close"
        //     ])
        //     //
        //     // sources.forEach((value) => {
        //     //   data.list.push(
        //     //     {
        //     //       obj:Dp.channel,
        //     //       // val:snap.val,
        //     //       key:"postMessage",
        //     //       // range:[0.0, 2]
        //     //     },
        //     //   );
        //     //   data.ids[value.name] = value.id;
        //     // })
        //   });
        // setOpen(true);


      // handleClickOpenDialog();
      // data.label = label;
      Dp.channel.postMessage({massage:"setMov",param:{ch:label,mov:callback.file,opacity:1.0,displayCapture:data.ids[value]}})

    }else if(label == "Web1" || label == "Web2" || label == "Web3") {
      Dp.channel.postMessage({massage:"setMov",param:{ch:label,mov:callback.file,opacity:1.0,displayCapture:data.ids[value]}})

    }else{
      if(typeof label == "string") Dp.channel.postMessage({massage:"setMov",param:{ch:label,mov:callback.file,opacity:1.0}})
    }
  };

  const uid = "menu"+Math.random();
  const imgid = "img"+Math.random();
  // const cvsid = "cvs"+Math.random();

  // React.useEffect(async () => {
  //   // Update the document title using the browser API
  //   console.log('componentDidMount now!');
  //   await Dp.makeThumb(document.getElementById(cvsid),callback.file);
  // });

  React.useEffect(() => {
    // Update the document title using the browser API
    console.log('componentDidMount now!');
    //await Dp.makeThumb(document.getElementById(cvsid),callback.file);
    // Dp.mov.func({ch:"chA",mov:callback.file,opacity:1.0,makeThumb:document.getElementById(cvsid)})

    // if(Dp.makeThumbs)Dp.makeThumbs.push({ch:"chA",mov:callback.file,opacity:0,makeThumb:document.getElementById(imgid)});
    // handleClickGetDisp();
    function update(){
      const done = Object.keys(thumbs).length
      var per = Math.floor((Dp.Movs.length - done) / Dp.Movs.length * 100);
      Dp.setProgress(per);

      if(Dp.Movs.length == done){
        Dp.setVals(Dp.snap.init.val);

        if(Dp.change){
          // Dp.thumbCreating = false;

          // const blob = Dp.buildBlob();
          // const url = URL.createObjectURL(blob);
          // Dp.link = document.createElement('a');
          // Dp.link.href =  url;
          // Dp.link.download = 'config.json';
          Dp.change = false;

          if(window.isElectron){
            Dp.overWrightFile();
            Dp.OBJ.setState("loaded");
          }else{
            Dp.OBJ.setState("thumbCreated");
          }

        }else{
         Dp.OBJ.setState("loaded");
        }

      }else if(Dp.progress.pre.length != 0 && Dp.progress.current.length < 3) {
        Dp.progress.current.unshift(Dp.progress.pre.shift());
        Dp.setVideo(Dp.progress.current[0][0],Dp.progress.current[0][1]);
        console.log(Dp.progress);

      }
    }

    const thumbs = Dp.snap.val.thumbs;
    const im = Dp.imagesFile[callback.file];

    if(Dp.snap.init.val.thumbs[callback.file]){
      thumbs[callback.file] = Dp.snap.init.val.thumbs[callback.file];
      setImg(thumbs[callback.file]);
      update()

    }else if(im){
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')
      canvas.width = 40;//video.videoWidth
      canvas.height = 29;//video.videoHeight

      context.drawImage(im, 0, 0, im.width, im.height, 0, 0, canvas.width, canvas.height)
      thumbs[callback.file] = canvas.toDataURL('image/jpeg');
      canvas.remove();

      setImg(thumbs[callback.file]);//img.src = thumbs[img];
      update();

    }else{
      const canvas = document.createElement('canvas')
      const video = document.createElement('video')
      const context = canvas.getContext('2d')

      //  読み込みが完了したらcanvas サイズを設定
      const onloadeddata = () => {
        canvas.width = 40;//video.videoWidth
        canvas.height = 29;//video.videoHeight
        video.currentTime = Math.ceil(video.duration / 4)
      }

      //  video.currentTime が変更されたらキャプチャ
      const onseeked = () => {
        Dp.change = true;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height)
        thumbs[callback.file] = canvas.toDataURL('image/jpeg');
        setImg(thumbs[callback.file]);//img.src = thumbs[img];

        video.removeEventListener( 'loadeddata', onloadeddata, false );
        video.removeEventListener( 'seeked', onseeked, false );
        video.remove();
        canvas.remove();

        Dp.progress.current.shift();
        update();
      }

      video.addEventListener( 'loadeddata', onloadeddata, false );
      video.addEventListener( 'seeked', onseeked, false );
      Dp.progress.pre.push([video,callback.file]);
      update();
    }

  },[]);



  const [menuPosition, setMenuPosition] = React.useState(null);

  const handleRightClick = async (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.clientY,
      left: event.clientX
    });

    await handleClickGetDisp();
  };

  // const handleItemClick = () => {
  //   setMenuPosition(null);
  // };

  const menu_chs =
  <Menu
    open={!!menuPosition}
    onClose={() => setMenuPosition(null)}
    anchorEl={anchorEl}
    anchorReference="anchorPosition"
    anchorPosition={menuPosition}
  >
  <MenuItem onClick={()=>{handleClose("chA")}}>Ch1</MenuItem>
  <MenuItem onClick={()=>{handleClose("chC")}}>Ch2</MenuItem>
  <MenuItem onClick={()=>{handleClose("chB")}}>Ch3</MenuItem>
  <MenuItem onClick={()=>{handleClose("chDJ")}}>Ch4</MenuItem>

    {/*
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
            <MenuItem onClick={()=>{handleClose("chA")}}>Ch1</MenuItem>
            <MenuItem onClick={()=>{handleClose("chC")}}>Ch2</MenuItem>
          </Menu>
          */}
    </Menu>

    const handleClickGetDisp = async () => {
      if(img != "displayCapture" || !window.api.desktopCapturer) return;
      const hasScreenCapturePermission = await Utl.AxiosWrap.get("/hasScreenCapturePermission");

      await Utl.desktopCapturer()
      data.list = [];
      data.ids = {};

      console.log(Utl.LastDesktopCapturer);

      Utl.LastDesktopCapturer.forEach((value) => {
        if(hasScreenCapturePermission.data == true || value.name == "Screen 1"|| value.name == "Screen 2"|| value.name == "Entire Screen"){
          data.list.push(value.id+":"+value.name);
          data.ids[value.id+":"+value.name] = value.id;
        }
      })

      // await window.api.desktopCapturer.getSources({ types: ['window', 'screen'] })
      //   .then(async sources => {
      //     data.list = [];
      //     data.ids = {};
      //     Utl.lastGetSources = sources;
      //     console.log(sources);
      //
      //     sources.forEach((value) => {
      //       if(hasScreenCapturePermission.data == true || value.name == "Screen 1"|| value.name == "Screen 2"|| value.name == "Entire Screen"){
      //         data.list.push(value.id+":"+value.name);
      //         data.ids[value.id+":"+value.name] = value.id;
      //       }
      //     })
      //   });
    };

    const menu_disp =
    <Menu
      open={!!menuPosition}
      onClose={() => setMenuPosition(null)}
      anchorReference="anchorPosition"
      anchorPosition={menuPosition}
    >

      <NestedMenuItem
        label="chA"
        parentMenuOpen={!!menuPosition}
        onClick={handleRightClick}
      >
        {data.list.map((display) => (
          <MenuItem onClick={() => handleClose("chA",display)} key={display}>{display}</MenuItem>
        ))}
      </NestedMenuItem>

        <NestedMenuItem
          label="chB"
          parentMenuOpen={!!menuPosition}
          onClick={handleRightClick}
        >
          {data.list.map((display) => (
            <MenuItem onClick={() => handleClose("chC",display)} key={display}>{display}</MenuItem>
          ))}
        </NestedMenuItem>
      </Menu>


  return (
    <div id = {uid}>
      {/*<SimpleDialog selectedValue={selectedValue} open={openDialog} onClose={handleCloseDialog} />*/}

      <Grid item className={classes.margin}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          key={"ui1"+callback.file}
        >
        <CardActionArea
        title={callback.file}
        onClick={handleRightClick}
        >
        <img src={img} alt={img}/>
        {/*Dp.thumbs[callback.file]
            ? <img src={Dp.thumbs[callback.file]} alt={callback.file} id={imgid}/>
            : <img src={"/img/noimage.png"} alt={callback.file} id={imgid}/>
        */}
        </CardActionArea>
      </Grid>
      {
        /*
        (callback.file == "displayCapture" && window.api.desktopCapturer)
        ? menu_disp
        : menu_chs
        */
        menu_chs
      }
    </div>
  );
}


Mov.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mov);
