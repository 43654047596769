import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
// import Obj from './Core';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
// import Iframe from 'react-iframe'
import Custompage from './Custompage';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';

import CardActionArea from '@material-ui/core/CardActionArea';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// import Utl from './Utl';

import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';

import InfoIcon from '@material-ui/icons/Info';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Mov from './Mov';
import RTFx from './RTFx';
import Mixer from './Mixer';
import Param from './Param';
import Bpm from './Bpm';
import Progress from './Progress';

import Axios from 'axios';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  frame: {
    frameborder:'no',
    display: "block",
    width: "100%",
    height: "300px"
  },
  win:{
    width:"100%"
  },
  sm12:{
    width:"100%"
  },
  sm6:{
    width:"50%"
  },
  iframe: {
    border: "none",
    position: "fixed",
    "z-index": 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  margin: {
    marginRight: '2px',
  },
  displayNone: {
    display: "none",
  },
  displayShow: {
    display: "",
  },
  dg:{
    hide:true
  },
  canvasframe:{
    display: "block",
    width: "100%",
    height: "auto"
  },
  canvasframewrapper:{
    display: "flex",
    width: "100%",
    "justify-content": "center",
    "align-items": "center",
  }
});

const windows = window.navigator.userAgent.toLowerCase().indexOf("windows nt") != -1;

const Home = props => {
  const { classes, user, db ,match,CONF,Utl,Dp} = props;
  const { param } = match.params;

  const [state, setState] = React.useState("init");
  const [movs, setMovs] = React.useState([]);
  const [fxs, setFxs] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [rts, setRts] = React.useState([]);
  const [params,setParams] = React.useState([]);

  const [value,update] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // var OBJ = {
  //   user:user,
  //   setState:(val)=>{setState(val)},
  //   setMovs:(val)=>{setMovs(val)},
  //   setFxs:(val)=>{setFxs(val)},
  //   setRts:(val)=>{setRts(val)},
  //   Add:(cb)=>{},
  // };

  const minH = 300;
  const updateWin = ()=>{
    if(Dp.fullscreenMode || !Dp.snap.val)return;

    const s = {
      dstW:Number(Dp.snap.val.cuts.dispWidth),
      dstH:Number(Dp.snap.val.cuts.dispHeight),
      winH:document.documentElement.clientHeight,
      winW:document.documentElement.clientWidth,
    }

    if(s.dstW + minH > s.winW) s.dstW = s.winW - minH;

    console.log(s);

    // if(s.dstW + minH < s.winW && Dp.snap.val.cuts.layout == "1 column") {
    if(Dp.snap.val.cuts.layout == "1 column") {
          document.getElementById("ui-wrapper").append(document.getElementById("canvas-frame-wrapper"));

        // Dp.fullscreenCanvas = document.createElement("canvas");
        // Dp.fullscreenCanvasCtx = Dp.fullscreenCanvas.getContext('2d');
        // document.getElementById("preview").append(Dp.fullscreenCanvas);
        // Dp.fullscreenCanvas.width = 400;
        // Dp.fullscreenCanvas.height = 225;
        // Dp.fullscreenCanvas.style.width = "100%";

        // console.log(win);
        // console.log(win[0]);
        // console.log(win[0].width);
        // console.log(win[0].width+"px");

        document.getElementById("root").style.position = "absolute";
        document.getElementById("root").style.left = s.dstW+"px";
        document.getElementById("root").style.top = 0;
        document.getElementById("root").style.width = (document.documentElement.clientWidth - s.dstW)+"px";//-10)+"px";
        // document.getElementById("root").style.height = (document.documentElement.clientHeight - Number(Dp.snap.val.cuts.dispHeight))+"px";

        const stylesheet = document.styleSheets.item(0);
        // var style = Utl.getRuleBySelector(".MuiDialog-root")
        var style = document.querySelectorAll('.MuiDialog-root');
        if(style.length !== 0){
          style = style[0].style
        // if(style){
        //   style = style.style
        }else{
          style = stylesheet.insertRule(".MuiDialog-root {position: fixed;}", stylesheet.cssRules.length);
          style = document.styleSheets.item(style);
        }

        // const style = Utl.getRuleBySelector(".MuiDialog-root").style;
        // style.position = "absolute";
        style.left = s.dstW+"px";
        style.top = 0;
        style.width = (document.documentElement.clientWidth - s.dstW)+"px";//-10)+"px";
        style.height = document.documentElement.clientHeight+"px"//(document.documentElement.clientHeight - Number(Dp.snap.val.cuts.dispHeight))+"px";

        // var style2 = Utl.getRuleBySelector(".MuiBackdrop-root");
        var style2 = document.querySelectorAll('.MuiBackdrop-root');
        // if(style2){
        //   style2 = style2.style
        if(style2.length !== 0){
          style2 = style2[0].style
        }else{
          style2 = stylesheet.insertRule(".MuiBackdrop-root {opacity: 1;}", stylesheet.cssRules.length);
          style2 = document.styleSheets.item(style);
        }


        // const style2 = Utl.getRuleBySelector(".MuiBackdrop-root").style;
        // style2.position = "absolute";
        style2.left = s.dstW+"px";
        style2.top = 0;
        style2.width = (document.documentElement.clientWidth - s.dstW)+"px";//-10)+"px";
        style2.height = document.documentElement.clientHeight+"px";//(document.documentElement.clientHeight - Number(Dp.snap.val.cuts.dispHeight))+"px";

        // document.getElementById("primary-search-account-menu").style.left = 0;
        // document.getElementById("primary-search-account-menu").style.top = 0;
        // document.getElementById("primary-search-account-menu").style.width = (document.documentElement.clientWidth - s.dstW)+"px";//-10)+"px";
        // document.getElementById("primary-search-account-menu").style.height = (document.documentElement.clientHeight - Number(Dp.snap.val.cuts.dispHeight))+"px";

        document.getElementById("AppBarRoot").style.left = s.dstW+"px";
        document.getElementById("AppBarRoot").style.top = 0;
        document.getElementById("AppBarRoot").style.width = (document.documentElement.clientWidth - s.dstW)+"px";
        // document.getElementById("AppBarRoot").style.height = (document.documentElement.clientHeight - Number(Dp.snap.val.cuts.dispHeight))+"px";;

        console.log(document.getElementById("root").style.width);
        console.log(document.getElementById("root").style.height);

        document.getElementById("canvas-frame-wrapper").style["z-index"] = 1200;
        document.getElementById("canvas-frame-wrapper").style["background-color"] = "#000";
        document.getElementById("canvas-frame-wrapper").style.position = "fixed";
        document.getElementById("canvas-frame-wrapper").style.left = 0;
        document.getElementById("canvas-frame-wrapper").style.top = 0;
        document.getElementById("canvas-frame-wrapper").style.width = s.dstW+"px";
        document.getElementById("canvas-frame-wrapper").style.height = "100%";//Number(Dp.snap.val.cuts.dispHeight)+"px";

        // Dp.setMenu(Dp.menu2);
        // Dp.setPage("hideLogin_showmwnu");

      // }else{
      //   // alert("このモードは、セカンドディスプレイの接続が必要です。")
      //   Dp.renderer.requestFullscreen();
      //   // document.getElementById('canvas-frame').requestFullscreen();
      // }

      // console.log(document.getElementById("canvas-frame-wrapper").style);
    }else{

      document.getElementById("root").style.left = "";//win[0].x+"px";
      document.getElementById("root").style.top = "";//win[0].y+"px";
      document.getElementById("root").style.width = "";//(win[0].width-10)+"px";
      document.getElementById("root").style.height = "";//win[0].height+"px";

      const stylesheet = document.styleSheets.item(0);
      // const style = Utl.getRuleBySelector(".MuiDialog-root").style;
      var style = document.querySelectorAll('.MuiDialog-root')//[0].style;
      if(style.length !== 0){
        style = style[0].style
      }else{
        style = stylesheet.insertRule(".MuiDialog-root {position: fixed;}", stylesheet.cssRules.length);
        style = document.styleSheets.item(style);
      }

      style.left = 0;
      style.top = 0;
      style.width = "";
      style.height = "";

      // const style2 = Utl.getRuleBySelector(".MuiBackdrop-root").style;
      var style2 = document.querySelectorAll('.MuiBackdrop-root');
      if(style2.length !== 0){
        style2 = style2[0].style
      }else{
        style2 = stylesheet.insertRule(".MuiBackdrop-root {opacity: 1;}", stylesheet.cssRules.length);
        style2 = document.styleSheets.item(style);
      }

      style2.left = "";
      style2.top = "";
      style2.width = "";
      style2.height = "";

      document.getElementById("AppBarRoot").style.left = "";//win[0].x+"px";
      document.getElementById("AppBarRoot").style.top = "";//win[0].y+"px";
      document.getElementById("AppBarRoot").style.width = "";//(win[0].width)+"px";

      if(document.getElementById("preview")) document.getElementById("preview").append(document.getElementById("canvas-frame-wrapper"));
      document.getElementById("canvas-frame-wrapper").style["z-index"] = "";
      document.getElementById("canvas-frame-wrapper").style["background-color"] = "";
      document.getElementById("canvas-frame-wrapper").style.position = "";
      document.getElementById("canvas-frame-wrapper").style.left = "";
      document.getElementById("canvas-frame-wrapper").style.top = "";
      document.getElementById("canvas-frame-wrapper").style.width = "";
      document.getElementById("canvas-frame-wrapper").style.height = "";
      document.getElementById("ui-container").style.display = "";
    }
    update(Math.random());
  }

  Dp.OBJ = {
    user:user,
    // updateWin:updateWin,
    update:v=>{
      update(v);
      updateWin();
    },
    setState:(val)=>{
      setState(val)
      // updateWin();
    },
    setMovs:(val)=>{setMovs(val)},
    setFxs:(val)=>{setFxs(val)},
    setParams:(val)=>{setParams(val)},
    setBanks:(val)=>{
      setBanks(JSON.parse(JSON.stringify(val)));
    },
    setRts:(val)=>{setRts(val)},
    Add:(cb)=>{},
  }

  React.useEffect(() => {
      window.addEventListener( 'resize', updateWin, false );
      // return () => window.removeEventListener('resize', updateWin);
  },[])

  // var Dp = Dp//Obj(OBJ);
  // Dp = Dp;

  const linkClick = async ()=>{
    await Dp.overWrightFile();

    // Dp.link.click();
    // URL.revokeObjectURL(Dp.link.href);
    // Dp.link.remove();
    setState("loaded");
  }

  if(param) Dp.windowtype = param;
  else Dp.windowtype = "main";

  //Dp.channel = new BroadcastChannel('app-data-test');

  Dp.channel = {postMessage:(D) => {
    if(!Dp.snap.obj){
      console.log("Error:Dp.snap.obj not found!")
      return;
    }
  // window.addEventListener('storage', (event) => {
  //   console.log(event);
  //   var D = event.newValue;

     // if (event.storageArea != localStorage) return;
     // if (event.key === 'loggedIn') {
     //   // Do something with event.newValue
     // }

  // });
  //
  // Dp.channel.addEventListener ('message', (event) => {
  //   var D = event.data;
    // console.log(D);

    if(Dp.windowtype=="main"){

      if(D.massage == "setMov"){
        Dp.mov.func(D.param);

      }else if(D.massage == "1 column"){
        //
        // // document.getElementById("ui-container").style.display = "none";
        //
        // const win = [0];
        // Dp.windowsSize.data.map((val) => {
        //   if(val.bounds.x == 0 && val.bounds.y == 0) win[0] = val.bounds;
        //   else win.push(val.bounds);
        // });
        //
        // if(win.length > 1){
          // Dp.windowsSub = win[1];

          // window.addEventListener( 'resize', updateWin, false );
          updateWin();

      }else if(D.massage == "fullscreenOn"){
        Dp.fullscreenMode = true;

        // document.getElementById("ui-container").style.display = "none";
        // window.removeEventListener( 'resize', updateWin, false );

        const win = [0];
        Dp.windowsSize.data.map((val) => {
          if(val.bounds.x == 0 && val.bounds.y == 0) win[0] = val.bounds;
          else win.push(val.bounds);
        });

        if(win.length > 1){
          Dp.windowsSub = win[1];
          document.getElementById("ui-wrapper").append(document.getElementById("canvas-frame-wrapper"));

          Dp.fullscreenCanvas = document.createElement("canvas");
          Dp.fullscreenCanvasCtx = Dp.fullscreenCanvas.getContext('2d');
          document.getElementById("preview").append(Dp.fullscreenCanvas);
          Dp.fullscreenCanvas.width = 400;
          Dp.fullscreenCanvas.height = 225;
          Dp.fullscreenCanvas.style.width = "100%";

          console.log(win);
          // console.log(win[0]);
          // console.log(win[0].width);
          // console.log(win[0].width+"px");

          document.getElementById("root").style.left = win[0].x+"px";
          document.getElementById("root").style.top = win[0].y+"px";
          document.getElementById("root").style.width = (win[0].width)+"px";//-10)+"px";
          document.getElementById("root").style.height = win[0].height+"px";

          const stylesheet = document.styleSheets.item(0);
          var style = Utl.getRuleBySelector(".MuiDialog-root")
          if(style){
            style = style.style
          }else{
            style = stylesheet.insertRule(".MuiDialog-root {position: fixed;}", stylesheet.cssRules.length);
            style = document.styleSheets.item(style);
          }

          // const style = Utl.getRuleBySelector(".MuiDialog-root").style;
          style.left = win[0].x+"px";
          style.top = win[0].y+"px";
          style.width = (win[0].width)+"px";//-10)+"px";
          style.height = win[0].height+"px";

          var style2 = Utl.getRuleBySelector(".MuiBackdrop-root")
          if(style2){
            style2 = style2.style
          }else{
            style2 = stylesheet.insertRule(".MuiBackdrop-root {opacity: 1;}", stylesheet.cssRules.length);
            style2 = document.styleSheets.item(style);
          }

          // const style2 = Utl.getRuleBySelector(".MuiBackdrop-root").style;
          style2.left = win[0].x+"px";
          style2.top = win[0].y+"px";
          style2.width = (win[0].width)+"px";//-10)+"px";
          style2.height = win[0].height+"px";

          // document.getElementById("primary-search-account-menu").style.left = win[0].x+"px";
          // document.getElementById("primary-search-account-menu").style.top = win[0].y+"px";
          // document.getElementById("primary-search-account-menu").style.width = (win[0].width)+"px";//-10)+"px";
          // document.getElementById("primary-search-account-menu").style.height = win[0].height+"px";

          document.getElementById("AppBarRoot").style.left = win[0].x+"px";
          document.getElementById("AppBarRoot").style.top = win[0].y+"px";
          document.getElementById("AppBarRoot").style.width = (win[0].width)+"px";

          console.log(document.getElementById("root").style.width);
          console.log(document.getElementById("root").style.height);

          document.getElementById("canvas-frame-wrapper").style["z-index"] = 1200;
          document.getElementById("canvas-frame-wrapper").style["background-color"] = "#000";
          document.getElementById("canvas-frame-wrapper").style.position = "fixed";
          document.getElementById("canvas-frame-wrapper").style.left = win[1].x+"px";
          document.getElementById("canvas-frame-wrapper").style.top = win[1].y+"px";
          document.getElementById("canvas-frame-wrapper").style.width = win[1].width+"px";
          document.getElementById("canvas-frame-wrapper").style.height = win[1].height+"px";

          Dp.setMenu(Dp.menu2);
          Dp.setPage("hideLogin_showmwnu");
        }else{
          // alert("このモードは、セカンドディスプレイの接続が必要です。")
          Dp.renderer.requestFullscreen();
          // document.getElementById('canvas-frame').requestFullscreen();
        }

        // console.log(document.getElementById("canvas-frame-wrapper").style);

      }else if(D.massage == "setBank"){
        Dp.setVals(D.param.val)
        // Dp.fx.func(D.param,D.opt)

      }else if(D.massage == "setFx"){
        Dp.fx.func(D.param,D.opt)

      }else if(D.massage == "setFxDirect"){
        Dp.snap.obj.fx(D.param);
        // if(Dp.snap.val["material 3"].opacity + Dp.snap.val["material 5"].opacity > 0) Dp.snap.obj.cameraChange(1);
        // else Dp.snap.obj.cameraChange(2);

      }else  if(D.massage == "setBpm"){
        Dp.bpm(D.val);

      }else if(D.massage == "onSdpTextMain"){
        Dp.onSdpText(D.sdp);

      }else if(D.massage == "initPJ"){
        Dp.channel.postMessage({massage:"openPJ",Movs:Dp.Movs_bak,thumbs:Dp.snap.val.thumbs});

        //Mixer
        Dp.channel.postMessage({massage:"setMixer",ch:"material 1",val:Dp.snap.val["mesh_material 1"].fader});
        if(Dp.snap.val["chAthumb"])Dp.channel.postMessage({massage:"setMixer",ch:"chAthumb",val:Dp.snap.val["chAthumb"]});
        Dp.channel.postMessage({massage:"setMixer",ch:"material 2",val:Dp.snap.val["mesh_material 2"].fader});
        if(Dp.snap.val["chCthumb"])Dp.channel.postMessage({massage:"setMixer",ch:"chCthumb",val:Dp.snap.val["chCthumb"]});

        //BPM
        if(Dp.snap.val.TimeBase.bpm)Dp.channel.postMessage({massage:"getBpm",val:Dp.snap.val.TimeBase.bpm});

        Dp.makeOffer(Dp.renderer.captureStream());

        // if(windows){
        //   Dp.setPage("hideLogin");
        //
        // }else{
        if(D.fromWindowtype=="sub"){
          // document.getElementById("ui-wrapper").append(document.getElementById("canvas-frame-wrapper"));
          // document.getElementById("ui-container").style.display = "none";
          //
          // const win = [0];
          // Dp.windowsSize.data.map((val) => {
          //   if(val.bounds.x == 0 && val.bounds.y == 0) win[0] = val.bounds;
          //   else win.push(val.bounds);
          // });
          //
          // console.log(win);
          // console.log(win[0]);
          // console.log(win[0].width);
          // console.log(win[0].width+"px");
          //
          // document.getElementById("ui-iframe").style.left = win[0].x+"px";
          // document.getElementById("ui-iframe").style.top = win[0].y+"px";
          // document.getElementById("ui-iframe").style.width = win[0].width+"px";
          // document.getElementById("ui-iframe").style.height = win[0].height+"px";
          //
          // console.log(document.getElementById("ui-iframe").style.width);
          // console.log(document.getElementById("ui-iframe").style.height);
          //
          // document.getElementById("canvas-frame-wrapper").style["z-index"] = 1;
          // document.getElementById("canvas-frame-wrapper").style.position = "fixed";
          // document.getElementById("canvas-frame-wrapper").style.left = win[1].x+"px";
          // document.getElementById("canvas-frame-wrapper").style.top = win[1].y+"px";
          // document.getElementById("canvas-frame-wrapper").style.width = win[1].width+"px";
          // document.getElementById("canvas-frame-wrapper").style.height = win[1].height+"px";
          //
          // console.log(document.getElementById("canvas-frame-wrapper").style);
          //
          // Dp.setMenu([Dp.menu[1]]);
          // Dp.setPage("hideLogin_showmwnu");

        }else{
          Dp.setPage("hideLogin");
        }

      }else if(D.massage == "closeSub"){
        Dp.fullscreenMode = false;

        if(Dp.fullscreenCanvas)Dp.fullscreenCanvas.remove();

        // window.removeEventListener( 'resize', updateWin, false );

        //
        // document.getElementById("root").style.left = "";//win[0].x+"px";
        // document.getElementById("root").style.top = "";//win[0].y+"px";
        // document.getElementById("root").style.width = "";//(win[0].width-10)+"px";
        // document.getElementById("root").style.height = "";//win[0].height+"px";
        //
        // const style = Utl.getRuleBySelector(".MuiDialog-root").style;
        // style.left = "";
        // style.top = "";
        // style.width = "";
        // style.height = "";
        //
        // const style2 = Utl.getRuleBySelector(".MuiBackdrop-root").style;
        // style2.left = "";
        // style2.top = "";
        // style2.width = "";
        // style2.height = "";
        //
        // document.getElementById("AppBarRoot").style.left = "";//win[0].x+"px";
        // document.getElementById("AppBarRoot").style.top = "";//win[0].y+"px";
        // document.getElementById("AppBarRoot").style.width = "";//(win[0].width)+"px";
        //
        // document.getElementById("preview").append(document.getElementById("canvas-frame-wrapper"));
        // document.getElementById("canvas-frame-wrapper").style["z-index"] = "";
        // document.getElementById("canvas-frame-wrapper").style["background-color"] = "";
        // document.getElementById("canvas-frame-wrapper").style.position = "";
        // document.getElementById("canvas-frame-wrapper").style.left = "";
        // document.getElementById("canvas-frame-wrapper").style.top = "";
        // document.getElementById("canvas-frame-wrapper").style.width = "";
        // document.getElementById("canvas-frame-wrapper").style.height = "";
        // document.getElementById("ui-container").style.display = "";

        Dp.setMenu(Dp.menu);
        Dp.setPage(true);
        setState("loaded");
      }

      // }else if(D.massage == "setRTFx"){
      //   Dp.rtfxFunc(D.param)
      // }

    }else{
      if(D.massage == "fullscreenOff"){
        Dp.windowsMain = null;
        Dp.channel.postMessage({massage:"closeSub"});

      }else if(D.massage == "onSdpTextSub"){
        Dp.onSdpText(D.sdp);

      }else if(D.massage == "setMixer"){
        Dp[D.ch](D.val);

      }else if(D.massage == "getBpm"){
        Dp.setBpm(D.val);

      }else if(D.massage == "openPJ"){
        Dp.thumbs = D.thumbs;
        setMovs(D.Movs);
        setState("loaded");
        console.log("loaded")
        console.log(D)
      }

      // }else if(D.massage == "setRTFx"){
      //   Dp.rtfxFunc(D.param)
      // }
    //
    // }else if(D.massage == "hideMixer"){
    //   Dp.windowtype = "main"
    // //Dp.channel.postMessage({massage:"hidePreview"});
    //
    // }else if(D.massage == "hidePreview"){
    //   Dp.windowtype = "sub"
    //   Dp.remoteVideo = document.createElement( 'video' );//document.getElementById('remote_video');
    //   Dp.remoteVideo.autoplay = true;
    //   Dp.remoteVideo.style.display = 'block';
    //   Dp.remoteVideo.style.width = '100%';
    //   Dp.remoteVideo.style.height = 'auto';
    //
    //   Dp.localStream = document.createElement("canvas").captureStream();
    //   document.getElementById( 'canvas-frame' ).appendChild( Dp.remoteVideo );
    //   console.log(D)

    }

    //channels.channel.callback(D);
  }};


  //
  // if(!Dp.channel){
  //   Dp.channel = {postMessage:(msg)=>{
  //     console.log(msg);
  //     console.log("Not prepare yet!Thearfor This msg will ignore!");
  //     // window.localStorage.setItem("postMessage", JSON.stringify(msg));
  //   }}
  // }

  // Dp.WEBRTCPreview = ()=>{

    //var canvasElem = Dp.snap.obj.renderer;//document.getElementById('local_canvas');
    //var ctx = canvasElem.getContext('2d');

    // let localVideo = document.getElementById('local_video');
    var remoteVideo;// = document.getElementById('remote_video');
    //var localStream = canvasElem.captureStream();
    //let localStream = null;
    let peerConnection = null;
    let textForSendSdp = {};//document.getElementById('text_for_send_sdp');
    let textToReceiveSdp = {};//document.getElementById('text_for_receive_sdp');
    //
    // function animate() {
    //   requestAnimationFrame(animate);
    //   if(main)return;
    //   ctx.font = '50pt Arial';
    //   ctx.fillMuiDialog-root = "#" + Math.floor(Math.random() * 16777215).toString(16);
    //   ctx.fillText(main, Math.random()*20, Math.random()*50);
    //   // ctx.strokeText(main, Math.random()*20, Math.random()*50);
    //   // ctx.fillMuiDialog-root = "#" + Math.floor(Math.random() * 16777215).toString(16);
    //   // ctx.strokeMuiDialog-root = "#" + Math.floor(Math.random() * 16777215).toString(16);
    //   // ctx.rect(Math.random()*25, Math.random()*25, Math.random()*100, Math.random()*100);
    //   // ctx.fill();
    //   // ctx.stroke();
    // }
    // animate()

    // --- prefix -----
    navigator.getUserMedia  = navigator.getUserMedia    || navigator.webkitGetUserMedia ||
                              navigator.mozGetUserMedia || navigator.msGetUserMedia;
    RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
    RTCSessionDescription = window.RTCSessionDescription || window.webkitRTCSessionDescription || window.mozRTCSessionDescription;

    // ---------------------- media handling -----------------------
    // //
    // // function isUseVideo() {
    // //   let useVideo = document.getElementById('use_video').checked;
    // //   return useVideo;
    // // }
    // //
    // // function isUseAudio() {
    // //   let useAudio = document.getElementById('use_audio').checked;
    // //   return useAudio;
    // // }
    // //
    // // function isSendOnly() {
    // //   let sendOnly = document.getElementById('send_only').checked;
    // //   return sendOnly;
    // // }
    //
    // // start local video
    // function startVideo() {
    //   let useVideo = isUseVideo();
    //   let useAudio = isUseAudio();
    //   if ( (! useVideo) && (! useAudio) ) {
    //     console.warn('NO media to capture');
    //     return;
    //   }
    //
    //   getDeviceStream({video: useVideo, audio: useAudio}) // audio: false
    //   .then(function (stream) { // success
    //     stream = localStream
    //     logStream('localStream', stream);
    //     localStream = stream;
    //     playVideo(localVideo, stream);
    //     bc.postMessage({massage:"connect"});
    //
    //   }).catch(function (error) { // error
    //     console.error('getUserMedia error:', error);
    //     return;
    //   });
    // }
    //
    // // stop local video
    // function stopVideo() {
    //   pauseVideo(localVideo);
    //   stopLocalStream(localStream);
    // }
    //
    // function stopLocalStream(stream) {
    //   let tracks = stream.getTracks();
    //   if (! tracks) {
    //     console.warn('NO tracks');
    //     return;
    //   }
    //
    //   for (let track of tracks) {
    //     track.stop();
    //   }
    // }
    //
    // function logStream(msg, stream) {
    //   console.log(msg + ': id=' + stream.id);
    //
    //   var videoTracks = stream.getVideoTracks();
    //   if (videoTracks) {
    //     console.log('videoTracks.length=' + videoTracks.length);
    //     for (var i = 0; i < videoTracks.length; i++) {
    //       var track = videoTracks[i];
    //       console.log('track.id=' + track.id);
    //     }
    //   }
    //
    //   var audioTracks = stream.getAudioTracks();
    //   if (audioTracks) {
    //     console.log('audioTracks.length=' + audioTracks.length);
    //     for (var i = 0; i < audioTracks.length; i++) {
    //       var track = audioTracks[i];
    //       console.log('track.id=' + track.id);
    //     }
    //   }
    // }
    //
    // function getDeviceStream(option) {
    //   if ('getUserMedia' in navigator.mediaDevices) {
    //     console.log('navigator.mediaDevices.getUserMadia');
    //     return navigator.mediaDevices.getUserMedia(option);
    //   }
    //   else {
    //     console.log('wrap navigator.getUserMadia with Promise');
    //     return new Promise(function(resolve, reject){
    //       navigator.getUserMedia(option,
    //         resolve,
    //         reject
    //       );
    //     });
    //   }
    // }

    function playVideo(element, stream) {
      if ('srcObject' in element) {
        if (! element.srcObject) {
          element.srcObject = stream;
        }
        else {
          console.log('stream alreay playnig, so skip');
        }
      }
      else {
        element.src = window.URL.createObjectURL(stream);
      }
      // element.play();
      element.volume = 0;
    }

    function pauseVideo(element) {
      element.pause();
      if ('srcObject' in element) {
        element.srcObject = null;
      }
      else {
        if (element.src && (element.src !== '') ) {
          window.URL.revokeObjectURL(element.src);
        }
        element.src = '';
      }
    }

    // ----- hand signaling ----
    Dp.onSdpText = (sdp)=>{
      textToReceiveSdp.value = sdp;
      let text = sdp;
      text = _trimTailDoubleLF(text); // for Safar TP --> Chrome
      if (Dp.peerConnection) {
        console.log('Received answer text...');
        let answer = new RTCSessionDescription({
          type : 'answer',
          sdp : text,
        });
        setAnswer(answer);
      }
      else {
        console.log('Received offer text...');
        let offer = new RTCSessionDescription({
          type : 'offer',
          sdp : text,
        });
        setOffer(offer);
      }
      textToReceiveSdp.value ='';
    }

    function sendSdp(sessionDescription) {
      console.log('---sending sdp ---');
      textForSendSdp.value = sessionDescription.sdp;
      // textForSendSdp.focus();
      // textForSendSdp.select();

      if(Dp.windowtype == "main"){
        Dp.channel.postMessage({massage:"onSdpTextSub",sdp:sessionDescription.sdp});
      }else{
        Dp.channel.postMessage({massage:"onSdpTextMain",sdp:sessionDescription.sdp});
      }

      //Dp.channel.postMessage({massage:"hidePreview"});
    }
    //
    // function copySdp() {
    //   textForSendSdp.focus();
    //   textForSendSdp.select();
    //   document.execCommand('copy');
    // }

    function _trimTailDoubleLF(str) {
      const trimed = str.trim();
      return trimed + String.fromCharCode(13, 10);
    }

    // ---------------------- connection handling -----------------------
    function prepareNewConnection() {
      let pc_config = {"iceServers":[]};
      let peer = new RTCPeerConnection(pc_config);

      // --- on get remote stream ---
      if ('ontrack' in peer) {
        peer.ontrack = function(event) {
          console.log('-- peer.ontrack()');
          let stream = event.streams[0];
          playVideo(Dp.remoteVideo, stream);
          if (event.streams.length > 1) {
            console.warn('got multi-stream, but play only 1 stream');
          }
        };
      }
      else {
        peer.onaddstream = function(event) {
          console.log('-- peer.onaddstream()');
          let stream = event.stream;
          playVideo(Dp.remoteVideo, stream);
        };
      }

      // --- on get local ICE candidate
      peer.onicecandidate = function (evt) {
        if (evt.candidate) {
          console.log(evt.candidate);

          // Trickle ICE の場合は、ICE candidateを相手に送る
          // Vanilla ICE の場合には、何もしない
        } else {
          console.log('empty ice event');

          // Trickle ICE の場合は、何もしない
          // Vanilla ICE の場合には、ICE candidateを含んだSDPを相手に送る
          sendSdp(peer.localDescription);
        }
      };


      // -- add local stream --
      if (Dp.localStream) {
        console.log('Adding local stream...');

        Dp.localStream.getTracks().forEach(
            track => {
              peer.addTrack(
                  track,
                  Dp.localStream
              );
            }
        );

        //
        // if ('addTrack' in peer) {
        //   console.log('use addTrack()');
        //   let tracks = localStream.getTracks();
        //   for (let track of tracks) {
        //     let sender = peer.addTrack(track, localStream);
        //   }
        // }
        // else {
        //   console.log('use addStream()');
        //   peer.addStream(localStream);
        // }
      }
      else {
        console.warn('no local stream, but continue.');
      }

      return peer;
    }

    Dp.makeOffer = (stream) => {
      Dp.localStream = stream;
      Dp.peerConnection = prepareNewConnection();

      let options = {offerToReceiveAudio: true, offerToReceiveVideo: true};
      if(Dp.windowtype == "main") options = { offerToReceiveAudio: false, offerToReceiveVideo: false };

      if (Dp.localStream) {
        if (false) {
          console.log('-- try sendonly ---');
          options = { offerToReceiveAudio: false, offerToReceiveVideo: false };
        }
      }
      else {
        // -- no localStream, so receive --
        console.log('-- try recvonly ---');

        options = { offerToReceiveAudio: true, offerToReceiveVideo: true };

        if ('addTransceiver' in Dp.peerConnection) {
          console.log('-- use addTransceiver() for recvonly --');
          Dp.peerConnection.addTransceiver('video').setDirection('recvonly');
          Dp.peerConnection.addTransceiver('audio').setDirection('recvonly');
        }
      }

      Dp.peerConnection.createOffer(options)
      .then(function (sessionDescription) {
        console.log('createOffer() succsess in promise');
        return Dp.peerConnection.setLocalDescription(sessionDescription);
      }).then(function() {
        console.log('setLocalDescription() succsess in promise');

        // -- Trickle ICE の場合は、初期SDPを相手に送る --
        // -- Vanilla ICE の場合には、まだSDPは送らない --
        //sendSdp(peerConnection.localDescription);
      }).catch(function(err) {
        console.error(err);
      });
    }

    function setOffer(sessionDescription) {
      if (Dp.peerConnection) {
        console.error('peerConnection alreay exist!');
      }
      Dp.peerConnection = prepareNewConnection();
      Dp.peerConnection.setRemoteDescription(sessionDescription)
      .then(function() {
        console.log('setRemoteDescription(offer) succsess in promise');
        makeAnswer();
      }).catch(function(err) {
        console.error('setRemoteDescription(offer) ERROR: ', err);
      });
    }

    function makeAnswer() {
      console.log('sending Answer. Creating remote session description...' );
      if (! Dp.peerConnection) {
        console.error('peerConnection NOT exist!');
        return;
      }

      let options = {};
      if (! Dp.localStream) {
        //options = { offerToReceiveAudio: true, offerToReceiveVideo: true }

        if ('addTransceiver' in peerConnection) {
          console.log('-- use addTransceiver() for recvonly --');
          Dp.peerConnection.addTransceiver('video').setDirection('recvonly');
          Dp.peerConnection.addTransceiver('audio').setDirection('recvonly');
        }
      }

      Dp.peerConnection.createAnswer(options)
      .then(function (sessionDescription) {
        console.log('createAnswer() succsess in promise');
        return Dp.peerConnection.setLocalDescription(sessionDescription);
      }).then(function() {
        console.log('setLocalDescription() succsess in promise');

        // -- Trickle ICE の場合は、初期SDPを相手に送る --
        // -- Vanilla ICE の場合には、まだSDPは送らない --
        //sendSdp(peerConnection.localDescription);
      }).catch(function(err) {
        console.error(err);
      });
    }

    function setAnswer(sessionDescription) {
      if (! Dp.peerConnection) {
        console.error('peerConnection NOT exist!');
        return;
      }

      Dp.peerConnection.setRemoteDescription(sessionDescription)
      .then(function() {
        console.log('setRemoteDescription(answer) succsess in promise');
      }).catch(function(err) {
        console.error('setRemoteDescription(answer) ERROR: ', err);
      });
    }
    //
    // // start PeerConnection
    // function connect() {
    //   if (! peerConnection) {
    //     console.log('make Offer');
    //     makeOffer();
    //   }
    //   else {
    //     console.warn('peer already exist.');
    //   }
    // }
    //
    // // close PeerConnection
    // function hangUp() {
    //   if (peerConnection) {
    //     console.log('Hang up.');
    //     peerConnection.close();
    //     peerConnection = null;
    //     pauseVideo(remoteVideo);
    //   }
    //   else {
    //     console.warn('peer NOT exist.');
    //   }
    // }


  //   const bc = new BroadcastChannel('app-data');
  //   var main = true;
  //
  //   bc.addEventListener ('message', (event) => {
  //     var D = event.data;
  //     console.log(D);
  //
  //     if(D.massage == "connect"){
  //       main = false;
  // //      connect();
  //       Dp.makeOffer();
  //
  //     }else if(D.massage == "pc2.setRemoteDescription"){
  //       textToReceiveSdp.value = D.sdp;
  //       Dp.onSdpText(D.sdp);
  //
  //     }
  //   });
  //
  //   bc.postMessage({massage:"connect"});
    //startVideo();
  // }

  // React.useEffect(() => {
  //   if(Dp.renderer) document.getElementById( 'canvas-frame' ).append( Dp.renderer.domElement );
  // });

  React.useLayoutEffect(() => {
    console.log('useLayoutEffect');
    // alert(window.navigator.userAgent);
    // alert(windows);

    // console.log("InitPJ")
    // if(!CONF.web && Dp.snap.obj){
    //   Dp.initPj();
    //   document.getElementById( 'canvas-frame' ).appendChild(Dp.renderer);
    // }

    if(user.data=="error"){
      window.history.pushState(null, null, '#/Mypage');
      return;
    }

    if(!user.emailVerified){
      Dp.setPage("hideLogin");
      return;
    }

    if(Dp.windowtype=="sub") {
      // if(windows){
      //   document.getElementById("ui-wrapper").append(document.getElementById("canvas-frame-wrapper"));
      //   document.getElementById("ui-container").MuiDialog-root.display = "none";
      //   Dp.setMenu([Dp.menu[1]]);
      //   Dp.setPage(false);
      // }else{
        Dp.setPage("hideLogin");
      // }

    } else if(Dp.windowtype=="screen") {
      // if(windows){
        document.getElementById("ui-wrapper").append(document.getElementById("canvas-frame-wrapper"));
        document.getElementById("ui-container").style.display = "none";
        Dp.setMenu([Dp.menu[1]]);
        Dp.setPage("hideLogin_showmwnu");
      // }else{
        // Dp.setPage("hideLogin");
      // }
    }

    //javascriptで普通に書く場合
    window.onbeforeunload = function(){
      Dp.channel.postMessage({massage:"closeSub"});
    }
    // Dp.channel.postMessage({massage:"hideMixer"});
  }, []);

  var layout = "";
  if(Dp.snap.val)layout=Dp.snap.val.cuts.layout;

  var app = (
    <React.Fragment>
    <Grid className={(state=="init")?classes.caption:classes.displayNone} >
      <Typography component="h2" variant="h5" gutterBottom>
        動画を読み込むフォルダを選んでください。
      </Typography>
      <Typography>※サンプル動画は、ダウンロードしたZIPを解凍したフォルダを読み込んでください。</Typography>
      <Button variant="contained" color="primary"　onClick={()=>{Dp.openPj("/showDirectoryPicker")}} className={classes.button}>
        フォルダを開く
      </Button>
      <Button variant="contained" color="primary"　onClick={()=>{Axios.get("/openExternal/"+CONF.firebase.web+"/downloads/movies.zip")}}  className={classes.button}>
        サンプル動画
      </Button>
    </Grid>

    <Grid className={(state=="thumbCreating")?classes.caption:classes.displayNone}>
      <Typography component="h2" variant="h5" gutterBottom>
        ムービーのサムネイルを作成中です。
      </Typography>
      <Progress Dp={Dp} key={Math.random()}/>
    </Grid>

    <Grid className={(state=="thumbCreated")?classes.caption:classes.displayNone}>
      <Typography component="h2" variant="h5" gutterBottom>
        サムネイルを開いたフォルダに保存してください（次回起動を高速化）
      </Typography>
      <Button variant="contained" color="primary"　onClick={linkClick}>
        フォルダへ保存
      </Button>
    </Grid>

    <Grid className={(state=="loading")?classes.caption:classes.displayNone}>
      <CircularProgress disableShrink />
    </Grid>

    <Grid id="ui-wrapper" className={(state=="loaded" || state=="loaded_opensub")?classes.caption:classes.displayNone}>
      <Grid container spacing={1} id="ui-container" className={classes.win}>
        <Grid item className={(layout=="2 column")?classes.sm6:classes.sm12} id="ui-left">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} id="preview" className={(layout=="2 column")?classes.displayShow:classes.displayNone}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Preview</Typography>
              </Grid>
             </Grid>
             <div id = "canvas-frame-wrapper" className={classes.canvasframewrapper}>
                  <div id="canvas-frame" className={classes.canvasframe}></div>
              </div>
            </Grid>
          <Grid item xs={12} sm={(layout=="2 column")?6:12}>
          <Grid container spacing={1}>
            <Grid item>
            <Typography>Mixer</Typography>
            </Grid>
           </Grid>

            <List dense={true}>
            {["material 1","material 2","material 3","material 5"].map((val,key) => (
                <Mixer Dp={Dp} ch={val} key={"Mixer::"+key}/>
            ))}
            {params.map((val,key) => (
                <Param Dp={Dp} ch={val} key={"Param::"+key}/>
            ))}
            <Bpm Dp={Dp} key={"Bpm"}/>
            </List>

          </Grid>
          </Grid>

          <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Movies</Typography>
              </Grid>
             </Grid>
             <Grid container>
             {movs.map((file,key) => (
               <Mov Dp={Dp} callback={{file:file}} key={"Mov::"+key}/>
             ))}
             </Grid>
          </Grid>
        </Grid>
        <Grid item className={(layout=="2 column")?classes.sm6:classes.sm12} id="ui-right">
          <Grid container>

            <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item>
              <Typography>Banks</Typography>
              </Grid>
             </Grid>
              <Grid container>
              {banks.map((tile,key) => (
                <React.Fragment key={"banks::"+key}>
                  {key==0 ?"":
                    <Grid item className={classes.margin}>
                      <CardActionArea title={tile.name} onClick={()=>{Dp.channel.postMessage({massage:"setBank",param:Utl.jsonCopy(tile)});}}>
                        {tile.thumb?
                          <img src={tile.thumb} alt={tile.name} />
                          :
                          <img src={"/img/noimage.png"}/>
                        }
                      </CardActionArea>
                    </Grid>
                  }
                </React.Fragment>
              ))}
              </Grid>
            </Grid>

            <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item>
              <Typography>Drug Effects</Typography>
              </Grid>
             </Grid>
              <Grid container>
              {rts.map((file,key) => (
                <RTFx Dp={Dp} callback={{file:file}} key={"RTFx::"+key}/>
              ))}
              </Grid>
            </Grid>

            <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item>
              <Typography>Click Effects</Typography>
              </Grid>
             </Grid>
              <Grid container>
              {fxs.map((tile,key) => (
                <Grid item className={classes.margin}  key={"fxs::"+key}>
                  <CardActionArea onClick={()=>{Dp.channel.postMessage({massage:"setFx",param:tile,opt:{key:key}});}}>
                    <img src={"/Resources/Extention/thumb,"+tile.thumb[0]+tile.thumb[1]+".png"} alt={tile.thumb[0]+tile.thumb[1]} />
                  </CardActionArea>
                </Grid>
              ))}
              </Grid>
            </Grid>

            <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item>
              <Typography></Typography>
              </Grid>
             </Grid>
              <Grid container>
              <Custompage {...{user, db ,match,CONF,Utl,Dp}}/>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
      </React.Fragment>
    )

    const msg = (
      <Grid className={classes.caption}>
      <Typography component="h2" variant="h5" gutterBottom>確認メールを送信しました。</Typography>
      <Typography gutterBottom>メール記載のURLをクリックしてからアプリを再起動してください。</Typography>
      </Grid>
    )

    if(CONF.web && user.emailVerified){
      window.location.href = "/#/mypage";
      // app = (
      //   <Grid className={classes.caption}>
      //   <Typography component="h2" variant="h5" gutterBottom>ライセンスの認証が完了しました。</Typography>
      //   <Typography gutterBottom>対象OSのアプリを起動することで、ご利用頂くことが可能です。</Typography>
      //   </Grid>
      // )
    }

  const msg2 = (
    <React.Fragment>
      { // We need to mount the Decoder component only after the user info became available.
        (user.emailVerified) ? app: msg
      }
    </React.Fragment>
  )

  return (
    <React.Fragment>
    <Header user={user} login="/Login/target/Home" Dp={Dp}/>
      <Grid container justify="center" alignItems="center" direction="row" className={classes.root}>
        { // We need to mount the Decoder component only after the user info became available.
          (user) ? msg2 : msg
        }
      </Grid>
    </React.Fragment>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
