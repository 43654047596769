import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
//import Slider from '@material-ui/core/Slider';

import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

//
// const label = {
//   "material 1":"Ch1",
//   "material 2":"Ch2",
// }
// const label2 = {
//   "material 1":"chA",
//   "material 2":"chC",
// }
// const label3 = {
//   "material 1":" 1",
//   "material 2":" 2",
// }
const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  margin: {
    marginRight: '2px',
  },
});

// const options = [
//   'chA 100%',
//   'chB 100%',
// ];

const ITEM_HEIGHT = 48;
const save = {last:new Date().getTime(),taps:[]};

const Bpm = props => {
  const { classes, callback ,Dp} = props;

  const [value, setValue] = React.useState(140);//callback.obj[callback.key]);
  Dp.setBpm = setValue;

  // const [thumb, setThumb] = React.useState("/img/noimage.png");//callback.obj[callback.key]);
  // Dp[label2[callback.ch]+"thumb"] = setThumb;

  const handleChange = (event) => {
    // if(newValue === undefined){var val = event.target.value}
    // else{var val = newValue.props.value}

    // console.log("GUI:"+val);
    // callback.obj[callback.key] = val;
    // callback.func(val);
    // Obj(newValue);
    // Width(newValue);
    // setValue(val);
    //callback.Mov.func({ch:callback.ch,opacity:Number(val)})

    // var param = {data:{material:{}}};
    // param.data.material[label3[callback.ch]] = ;
    console.log({massage:"setBpm",val:Number(event.target.value)});
    Dp.channel.postMessage({massage:"setBpm",val:Number(event.target.value)})
    //Dp.channel.postMessage({massage:"func",param:{name:callback.ch,func:"opacity",value:Number(val)}})
    //Dp.snap.obj[callback.ch].opacity(Number(val));
  };

  const delClick = async () => {
    save.bpm = new Date().getTime() - save.last;
    save.last = new Date().getTime();
    // console.log(save.bpm)
    // console.log(60 * 1000 / 30)

    if(save.bpm < 60 * 1000 / 30){
      console.log(save.taps)
      save.taps.push(save.bpm);
      save.bpm = save.taps.reduce((sum, element) => sum + element, 0) / save.taps.length;
      Dp.channel.postMessage({massage:"setBpm",val:60 * 1000 / save.bpm })
      // console.log("-------------BPM-----------")
      console.log(save.taps)
      // console.log(save.taps.reduce((sum, element) => sum + element, 0))
      // console.log(save.taps.length)
      // console.log(save.bpm)
      // console.log(60 * 1000 / save.bpm)

    }else{
      console.log("over:"+save.bpm)
      save.taps = [];
    }
    //if(label) Dp.channel.postMessage({massage:"setMov",param:{ch:label2[callback.ch],mov:"",opacity:0}})
    // Dp.channel.postMessage({massage:"func",param:{name:callback.ch,func:"opacity",value:0}})
    //await callback.Mov.func({ch:callback.ch,opacity:0})
  };

  const upClick = async () => {
    console.log(value)
    if(value < 200){
      Dp.channel.postMessage({massage:"setBpm",val:value + 0.5 })
    }
  };

  const downClick = async () => {
    console.log(value)
    if(value > 30){
      Dp.channel.postMessage({massage:"setBpm",val:value - 0.5 })
    }
  };


    React.useEffect(()=>{
      Dp.channel.postMessage({massage:"setBpm",val:140});
      // if(Dp.windowtype=="main"){Dp.channel.postMessage({massage:"setFxDirect",param:{cat:"material",lyr:label3[callback.ch],keys:{"fader":1}}})}
    }, []);

  //
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  //
  // const handleClick = (event) => {
  //   var el = document.getElementById(uid);
  //   console.log(el)
  //   setAnchorEl(el);//event.currentTarget);
  // };
  //
  //
  // const handleClose = (label) => {
  //   if(label) Dp.func({ch:label,mov:callback.file})
  //   setAnchorEl(null);
  // };
  //
  //

  const uid = "menu"+Math.random();

  return (
    <ListItem key={uid}>
      <Grid container spacing={1} text-align={"center"}>
        <Grid item>
          <ListItemText id="switch-list-label-bluetooth" primary="Bpm" />
        </Grid>
        <Grid item>
          <IconButton edge="end" aria-label="delete" className={classes.margin} size="small" onClick={delClick}>
            <MusicNoteIcon fontSize="inherit"/>
          </IconButton>
          <IconButton edge="end" aria-label="delete" className={classes.margin} size="small" onClick={upClick}>
            <KeyboardArrowUpIcon fontSize="inherit"/>
          </IconButton>
          <IconButton edge="end" aria-label="delete" className={classes.margin} size="small" onClick={downClick}>
            <KeyboardArrowDownIcon fontSize="inherit"/>
          </IconButton>
        </Grid>
        <Grid item>
          <ListItemText id="switch-list-label-bluetooth" primary={value.toFixed(1)} />
        </Grid>
     </Grid>
   </ListItem>
  );
}

Bpm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Bpm);
